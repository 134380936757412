@use "../../assets/stylesheets/flex.scss";
@use "../../assets/stylesheets/mixins.scss";

.base {
  @include flex.col(flex-start);
}

.loading-container {
  flex-grow: 1;
}

.tapered {
  @include mixins.tapered;
}
