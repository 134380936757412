@use "../../../../assets/stylesheets/breakpoints.scss";
@use "./_vars.scss";

@include breakpoints.desk-xxs {
  .avatar {
    width: vars.$avatar-col-width;
  }

  .name {
    width: vars.$name-col-width;
  }

  .email {
    width: vars.$email-col-width;
  }

  .bio {
    width: vars.$bio-col-width;
  }

  .categories {
    width: vars.$categories-col-width;
  }
}
