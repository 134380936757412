@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/flex.scss";

.container {
  @include flex.col();
  position: relative;
}

.body {
  @include flex.col(space-between);
  height: 100%;
  padding: 1rem 0 0;
  width: 100%;
}

.content {
  width: 100%;
}

@include breakpoints.tab-sm {
  .container {
    width: 35rem;
  }
}

@include breakpoints.desk-xxs {
  .container {
    width: 100%;
    height: 100%;
  }
}
