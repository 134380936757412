$max-layout-width: 100rem;
$max-content-width: 55rem;
$max-content-width-tab: 40rem;

// $padding-layout-horz-xs: 2rem;

$padding-layout-horz-sm: 1rem;
$padding-layout-vert-sm: 1rem;

$padding-layout-horz-md: 2rem;
// $padding-layout-vert-md: 6rem;

// $padding-layout-horz-lg: 6rem;
$padding-layout-horz-lg: 2rem;
// $padding-layout-vert-lg: 10rem;

$padding-layout-horz-xl: 2rem;
// $padding-layout-horz-xl: 12rem;
// $padding-layout-vert-xl: 14rem;

$padding-layout-horz-xxl: 2rem;
// $padding-layout-horz-xxl: 16rem;
// $padding-layout-vert-xxl: 20rem;

$PageContent-padding-top: 1rem;
