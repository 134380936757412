@use "./breakpoints.scss";
@use "./colors.scss";
@use "./flex.scss";
@use "./fonts.scss";
@use "./mixins.scss";

* {
  @include mixins.border-box-sizing;
}

html,
body {
  overflow-x: hidden;
}

html {
  background-color: colors.$darker;
  color: colors.$white;
  font-family: fonts.$base-font;
  height: 100%;
  min-height: 100vh;
}

body {
  font-size: fonts.$base-font-size;
  height: 100%;
  margin: 0;
  padding: 0;
  width: auto;
}

a {
  color: darken(colors.$white, 15%);
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: colors.$white;
  }
}

button,
input,
textarea {
  outline-color: colors.$primary;
}

textarea {
  font-family: inherit;
  font-size: inherit;
}

button {
  @include flex.col(flex-start, (align: flex-start));
  background: none;
  border: none;
  color: colors.$white;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
}

p {
  line-height: 1.2;
  margin: 0;
}

em {
  text-decoration: none;
  font-style: normal;
}

:root {
  --toastify-color-error: #a20023;
  --toastify-color-info: #CACACA;
  --toastify-color-success: #A8FF00;
  // --toastify-color-success: #A1D341;
  --toastify-text-color-info: #222222;
  --toastify-text-color-success: #222222;
  --toastify-toast-min-height: 1rem;
}

.Toastify__toast {
  margin-bottom: 0;
}

.Toastify__toast--info, .Toastify__toast--success {
  .Toastify__close-button {
    color: #222222;
  }
}

.Toastify__toast-container--top-center {
  min-width: 1em;
  max-width: 50em;
  width: 100%;;
}

iframe {
  display: none;
}

@include breakpoints.tab-sm {
  .Toastify__toast-container--top-center {
    width: fit-content;
  }
}

@include breakpoints.desk-xxs {
  .Toastify__toast-container--top-center {
    right: 1rem;
    top: unset;
    transform: none;
    left: unset;
    bottom: 1rem;
  }
}
