@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../../../../assets/stylesheets/fonts.scss";
@use "../../../../assets/stylesheets/mixins.scss";
@use "../Background/_vars.scss";

$card-bg-height: 12.5rem;

:global(.ChallengeCard) {
  @include flex.col(center, (full: true));
  background: transparent;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid colors.$grey-dark;
  color: colors.$grey;
  font-weight: 600;
  overflow: hidden;

  &:hover {
    border-color: colors.$white;

    .head {
      color: colors.$white;
    }
  }
}

.background {
  height: $card-bg-height;
}

.content {
  @include flex.col(space-between);
  padding: 1rem;
}

.header {
  @include flex.col(flex-start, (align: flex-start, full: true));
}

.title {
  font-size: 1.25em;
  overflow: hidden;
  text-align: left;
}

.description {
  color: colors.$grey;
  line-height: 1.25;
  overflow: hidden;
  text-align: left;
}

.head {
  @include flex.row(space-between, (full: true, pure: true));
  color: colors.$grey-light;
  cursor: pointer;
  padding: 1rem;
}

.body {
  @include flex.col(flex-start, (align: flex-start, full: true));
  @include mixins.transition(100ms ease-in-out);
  height: 0;
  opacity: 0;
}

.title_dropdown {
  @include flex.row(center, (pure: true));
  font-size: 1em;

  .title {
    @include mixins.ellipsis-clamp(1);
  }

  svg.chevron {
    @include mixins.transition(100ms ease-in-out);
    height: 1rem;
    margin-right: 1rem;
    width: 1rem;
  }
}

.description {
  @include flex.col(center);
  color: colors.$grey;
  font-weight: fonts.$font-weight-normal;
  margin-bottom: 1rem;
}

.rewards {
  @include flex.row(center, (pure: true));
  font-weight: 600;
  gap: 1rem;

  img {
    width: 1.25rem;
    cursor: help;
  }
}

.rewards {
  @include flex.row(center, (pure: true));

  .reward {
    margin-left: 1rem;
  }
}

.actions {
  @include flex.row(flex-end, (pure: true, full: true));
  align-items: center;
  gap: 1rem;
}

.more_details {
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
}

.complete {
  @include flex.row(center, (pure: true));
  font-weight: 600;
  line-height: 1.5;
  padding: 0.75rem 1.5rem;
  width: auto;

  svg {
    @include mixins.transition(100ms);
    height: 0;
    width: 0;
  }

  &:hover {
    svg {
      height: 1rem;
      margin-left: 0.5rem;
      width: 1rem;
    }
  }
}

.open {
  .head {
    color: colors.$white;
  }

  .body {
    height: auto;
    opacity: 1;
    padding: 1rem 1rem 1rem 2rem;
  }

  .title_dropdown svg.chevron {
    transform: rotate(90deg);
  }
}

@include breakpoints.desk-xxs {
  .head {
    padding: 1rem 0.25rem 0.25rem 0.25rem;
  }

  .actions {
    justify-content: flex-end;
  }
}
