@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/components.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/spacing.scss";
@use "../Layout/_vars.scss" as layoutVars;
@use "../Menu/vars.scss" as menuVars;
@use "./vars";

.base {
  @include flex.col(flex-start);
  @include components.responsive-container;
  min-height: layoutVars.$view-height-mobile;
  padding-bottom: menuVars.$menu-height;
  position: relative;

  &.no-padding {
    padding: 0;
  }

  &.col, &.form {
    @include flex.col(flex-start);
  }

  &.admin-form {
    width: 100%;

    :global(.PageContent), :global(.Tabs) {
      align-items: center;
      max-width: vars.$max-form-width;
      width: 100%;
    }
  }

  &.col {
    height: -webkit-fill-available;

    :global(.PageContent) {
      justify-content: flex-start;
    }
  }

  &.form {
    // @include components.responsive-container-pad;
    padding-bottom: menuVars.$menu-height;
    justify-content: center !important;

    :global(.PageContent), :global(.Tabs) {
      max-width: vars.$max-form-width;
    }
  }

  &.center {
    justify-content: center;
  }

  &.partial {
    height: auto;
  }
}

@include breakpoints.desk-xxs {
  .base {
    min-height: layoutVars.$view-height-desktop-with-header;
    // min-height: calc(100vh - menuVars.$menu-height-mobile);
    // min-height: 100vh;
    padding-top: spacing.$padding-layout-vert-sm;
    padding-bottom: spacing.$padding-layout-vert-sm;

    &.admin-form {
      padding: 0;

      :global(.PageContent) {
        padding: 2rem 0;
        justify-content: flex-start;
      }
    }
  }
}

@include breakpoints.desk-sm {
}
