@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";

.resource_results, .SearchBarResults {
  @include flex.col(center, (full: true));

  .LoadingContainer {
    @include flex.col(center, (full: true));
    // padding: 4rem 0;
    height: 20rem;
    flex-grow: 1;
  }
}

.resource_result {
  @include flex.col(center, (align: flex-start, full: true));
  border-bottom: 1px solid lighten(colors.$dark, 10%);
  padding: 1rem;

  &:hover {
    background: lighten(colors.$dark, 10%);
    background-color: colors.$darker;
  }
}
