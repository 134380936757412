.base {
  .uppy-StatusBar-statusPrimary button.uppy-StatusBar-details {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.preview-wrapper {
  height: 100%;
  position: relative;
}
