@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "../../../assets/stylesheets/spacing.scss";
@use "../../../components/cards/Cards/styles.mod.scss";
@use "../../../components/layouts/Header/vars.scss" as headerVars;
@use "./vars.scss";

.LoadingContainer, .NoContent {
  transform: none;
  top: unset;
}

.scrollable_messages {
  height: vars.$messages-container-height;
  display: flex;
  overflow: scroll;
  flex-direction: column-reverse !important;
  gap: 0.5rem !important;
  padding: 2px;
  justify-content: flex-start !important;
}

.form_wrapper {
  @include mixins.tapered;
  height: vars.$messages-input-height;
  width: 100%;
}

.form {
  @include flex.row(center, (full: true, pure: true));
  max-width: unset;
  width: 100%;
  padding: 0;
}

.form_container {
  @include flex.col(center, (full: true));
  height: vars.$messages-input-height;
}

.input_container {
  @include flex.col(center, (full: true));
  position: relative;

  input {
    outline: none;
    border: none;
    height: 2.5rem;
    color: colors.$white;
    padding: 0 3rem 0 1rem;
    border-radius: 2.5rem;
    width: 100%;
    background-color: colors.$dark;
  }

  button {
    width: 1.5rem;
    height: 1.5rem;
    color: colors.$primary;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(50%);
    width: 1rem;
    right: 1rem;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }
}

// .FormError {
//   font-size: 0.85rem;
//   font-weight: 400;
// }

@include breakpoints.desk-xxs {
  .PageContent {
    padding: 0;
  }
}
