@use "../../assets/stylesheets/breakpoints.scss";
@use "../../assets/stylesheets/colors.scss";
@use "../../assets/stylesheets/mixins.scss";
@use "../../assets/stylesheets/flex.scss";

.overlay {
  @include flex.col(center, (full: true));
  background: rgba(0, 0, 0, 0.8);
  padding: 0.5rem;
  // overflow: hidden;
  z-index: 11;
}

.close {
  @include flex.col(center);
  color: colors.$grey;
  height: 1rem;
  width: 1rem;

  &:hover {
    color: colors.$white;
  }
}

.content {
  @include flex.col(center);
  background-color: colors.$grey-darkest;
  border-radius: 6px;
  border: 1px solid colors.$grey-dark;
  color: #fff;
  padding: 1.5rem 1.5rem 3rem;
  position: relative;

  p {
    color: colors.$grey;
  }
}

.title {
  @include flex.row(space-between, (align: flex-start, full: true, pure: true));
  margin-bottom: 2rem;

  h2 {
    @include mixins.title-primary;
    margin: 0;
  }
}

@include breakpoints.tab-sm {
  .overlay {
    padding: 2rem;
  }
}
