@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../../../../assets/stylesheets/mixins.scss";

.hero {
  position: relative;

  img {
    object-position: center;
    object-position: top;
    object-position: bottom;
  }
}

.actions {
  @include flex.row(flex-start, (full: true));
}

.actions_desk {
  bottom: 1rem;
  display: none;
  gap: 0.25rem;
  left: 1rem;
  position: absolute;
  z-index: 1;
}

.actions_popover {
  @include flex.col();
  gap: 0.25rem;
  min-width: 9rem;
  padding: 0.5rem;

  .action {
    width: 100%;
    gap: 0.5rem;
    padding: 0.5rem;
  }
}

.actions_mobile {
  @include flex.row(space-between, (pure: true, full: true));
  top: 0;
  position: absolute;
  right: 0;
  padding: 1rem;
  z-index: 1;
}

.actions_popover_trigger {
  @include flex.row(center, (pure: true));
  background-color: colors.$dark;
  border-radius: 1.5rem;
  border: 1px solid colors.$grey-dark;
  box-shadow: unset;
  gap: 0.5rem;
  padding: 0.4rem 0.8rem;

  svg {
    height: 1rem;
    width: 1rem;
  }

  .ArrowDownIcon {
    color: colors.$white;
  }

  .FriendAddIcon {
    color: colors.$primary;
  }

  .RivalAddIcon {
    color: colors.$red;
  }

  .MessageIcon {
    color: colors.$grey;
  }
}

@include breakpoints.tab-md {
  .actions_desk {
    display: flex;
    gap: 0.5rem;

    .action {
      border-radius: 2rem;
      border-style: solid;
      border-width: 1px;
      border-width: 2px;
      box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.5);
      font-size: 0.85rem;
      gap: 0.5em;
      letter-spacing: 1px;
      padding: 0.5em 1em;

      .text {
        display: initial;
      }

      svg {
        height: 1rem;
        width: 1rem;
      }
    }
  }

  .actions_mobile {
    display: none;
  }
}
