@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../../../../assets/stylesheets/mixins.scss";

$img-size: 5rem;

:global(.LeagueCard) {
  min-height: 7rem;

  &:hover .img {
    border-color: colors.$grey-light;
  }
}

.description {
  @include mixins.ellipsis-clamp(3);
  color: colors.$grey;
  font-size: 0.9em;
  width: 100%;
}

.categories {
  @include flex.row(flex-end, (full: true, pure: true, wrap: true));
  gap: 0.25rem;
}

.container {
  @include flex.col();
  position: relative;
  min-height: 7rem;
  width: 100%;
}

.body {
  @include flex.col(center, (full: true));
  gap: 1rem;
}

.header {
  @include flex.row(space-between, (align: flex-start, full: true));
  gap: 1rem;
}

.name_members {
  @include flex.row(flex-start, (align: flex-start, full: true));
  gap: 1rem;
  height: 5rem;
  padding-left: calc(#{$img-size} + 1rem);
}

.name {
  @include mixins.ellipsis-clamp(2);
}

.members {
  color: lighten(colors.$secondary, 10%);
  font-size: 0.8em;
}

.img {
  border-radius: $img-size;
  border: 1px solid colors.$grey-dark;
  height: $img-size;
  object-fit: cover;
  position: absolute;
  width: $img-size;
}

@include breakpoints.desk-xxs {
  .body {
    padding-left: 3rem;
  }

  .img {
    left: -1.5rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .name_members {
    height: auto;
    padding: 0;
  }

  .header {
    align-items: space-between;
    padding-left: 0;

    .members {
      margin-left: 1rem;
    }
  }

  .categories {
    .Badge {
      margin-left: 0.5rem;
    }
  }
}
