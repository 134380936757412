@use "../../../../../assets/stylesheets/colors.scss";
@use "../../../../../assets/stylesheets/flex.scss";
@use "../../../../../assets/stylesheets/mixins.scss";

.wrapper {
  @include flex.row(flex-start, (pure: true));
  @include mixins.titleize;
  @include mixins.transition(150ms);
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 1;
  padding: 0.25rem 0.5rem;
  position: relative;
  width: fit-content;

  svg {
    height: 1rem;
    width: 1rem;
  }

  &:hover {
    filter: brightness(1.2);
  }
}
