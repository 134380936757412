@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/flex.scss";

.wrapper {
  gap: 1rem;
  // max-width: 20rem;
  padding: 0;
}

.options {
  @include flex.row(space-between, (align: flex-start, full: true));
}

.buttons {
  @include flex.col(center, (full: true));
  font-size: 0.75em;
  gap: 0.5rem;
}

@include breakpoints.desk-xxs {
  .buttons {
    margin-top: 2rem;
  }
}
