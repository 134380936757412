@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../../../../assets/stylesheets/mixins.scss";
@use "../../../../assets/stylesheets/spacing.scss";
@use "../../../layouts/Menu/vars.scss";

:global(.SubmissionCard) {
  max-height: 33rem;
  max-width: 80rem;

  .actions {
    height: 100%;
    gap: 3rem;
    flex-direction: row-reverse;
  }

  .content {
    flex-direction: column-reverse;
  }
}

.media_link {
  @include flex.col(center, (full: true));
  height: 100%;
}

@include breakpoints.tab-sm {
  .base {
    width: 35rem;
  }
}

@include breakpoints.desk-xxs {
  :global(.SubmissionCard) {
    height: 36rem;
    max-height: unset;
    width: 100%;

    .content {
      flex-direction: row;
      gap: 1rem;
    }

    .actions {
      flex-direction: column;
      font-size: 1em;
      align-items: flex-start;
      justify-content: flex-start;
      width: 1.5rem;
    }

    .subheader {
      flex-direction: row;
    }
  }
}
