@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../pages/admin/competitions/List/_vars.scss";

.awards {
  color: colors.$gold;
  border-color: colors.$gold;

  &:hover {
    background-color: colors.$gold;
    color: colors.$dark;
  }
}

.categories {
  composes: categories from '../../CardAdmin/styles.mod.scss';
}

@include breakpoints.desk-xxs {
  .name {
    width: vars.$name-col-width;

    a:hover {
      text-decoration: underline;
    }
  }

  .logo {
    width: vars.$logo-col-width;
  }

  .description {
    width: vars.$description-col-width;
  }

  .categories {
    width: vars.$categories-col-width;
  }

  .rewards {
    width: vars.$reward-col-width;
  }

  .actions {
    width: vars.$actions-col-width;
  }
}
