@use "../../../assets/stylesheets/flex.scss";

.UserCard, .UserCardExpanded {
  .Card__header {
    @include flex.col(flex-start, (align: flex-start, full: true));
  }

  .Card__categories {
    margin-top: 1rem;
  }

  .Card__content {
    @include flex.col(space-between);
    padding: 1rem;
  }

  .Card__title {
    font-size: 1.25em;
    overflow: hidden;
    margin-bottom: 0.5rem;
  }
}
