@use "../../../assets/stylesheets/breakpoints.scss";

.wrapper {
  width: 100%;
}

.header {
  margin-bottom: 2rem;

  h1 {
    font-size: 1.25em;
    font-weight: 500;
    margin: 0;
  }
}

@include breakpoints.desk-xxs {
  .header {
    margin-bottom: 4rem;

    h1 {
      font-size: 2em;
    }
  }
}
