@use "./breakpoints.scss";
@use "./spacing.scss";


@mixin responsive-container-pad {
  padding: 1rem;
}

@mixin responsive-container {
  padding: 0;

  // @include breakpoints.desk-xxs {
  //   padding-left: spacing.$padding-layout-horz-xs;
  //   padding-right: spacing.$padding-layout-horz-xs;
  // }

  @include breakpoints.tab-sm {
    padding-left: spacing.$padding-layout-horz-sm;
    padding-right: spacing.$padding-layout-horz-sm;
  }

  @include breakpoints.tab-sm {
    padding-left: spacing.$padding-layout-horz-sm;
    padding-right: spacing.$padding-layout-horz-sm;
  }

  // @include breakpoints.tab-lg-landscape {
  //   padding-left: spacing.$padding-layout-horz-xs;
  //   padding-right: spacing.$padding-layout-horz-xs;
  // }

  // @include breakpoints.desk-xxs {
  //   padding-left: spacing.$padding-layout-horz-md;
  //   padding-right: spacing.$padding-layout-horz-md;
  // }

  @include breakpoints.desk-xs {
    padding-left: spacing.$padding-layout-horz-md;
    padding-right: spacing.$padding-layout-horz-md;
  }

  @include breakpoints.desk-sm {
    padding-left: spacing.$padding-layout-horz-md;
    padding-right: spacing.$padding-layout-horz-md;
  }

  @include breakpoints.desk-md {
    padding-left: spacing.$padding-layout-horz-lg;
    padding-right: spacing.$padding-layout-horz-lg;
  }

  @include breakpoints.desk-lg {
    padding-left: spacing.$padding-layout-horz-xl;
    padding-right: spacing.$padding-layout-horz-xl;
  }

  @include breakpoints.desk-xl {
    padding-left: calc((100vw - spacing.$max-layout-width) / 2);
    padding-right: calc((100vw - spacing.$max-layout-width) / 2);
  }
}
