@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "../../../assets/stylesheets/colors.scss";

// :global(.TooltipContent) {
//   @include flex.row(center);
//   @include mixins.titleize;
//   background-color: colors.$dark;
//   border-radius: 0.25rem;
//   box-shadow: 0 0 2px 0 colors.$grey-dark;
//   color: colors.$grey-lighter;
//   font-size: 0.75rem;
//   outline: none;
//   padding: 0.5rem 1rem;
// }
.content {
  @include flex.row(center);
  @include mixins.titleize;
  background-color: colors.$dark;
  border-radius: 0.25rem;
  box-shadow: 0 0 2px 0 colors.$grey-dark;
  color: colors.$grey-lighter;
  font-size: 0.75rem;
  outline: none;
  padding: 0.5rem 1rem;
}
