@use "../../assets/stylesheets/breakpoints.scss";
@use "../../assets/stylesheets/colors.scss";
@use "../../assets/stylesheets/flex.scss";
@use "../../assets/stylesheets/mixins.scss";
@use "../layouts/Header/_vars.scss";

.base {
  @include flex.col();
  padding: 1rem;

  .rule-section, .rule {
    @include flex.col(flex-start, (align: flex-start, full: true));
    position: relative;

    .title {
      width: 100%;
    }
  }

  .anchor {
    position: absolute;
    top: calc(#{vars.$header-height-mobile} * -1);
    visibility: hidden;
  }

  .rule {
    padding-bottom: 4rem;
    padding-top: 1rem;
  }

  h1, h2 {
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  h1 {
    color: colors.$primary;
    font-size: 1.25em;
    margin: 0.5rem 0 2rem;
    border-bottom: 1px solid;
    padding-bottom: 1rem;
  }

  h2 {
    @include flex.row(center, (pure: true));
    color: colors.$grey-light;
    font-size: 1em;
    margin: 2rem 0 1rem;
  }
}

.rule-section {
  .content {
    @include flex.col();
    @include mixins.transition(all 150ms ease);
    max-height: 0;
    overflow: hidden;
  }

  .title svg {
    @include mixins.transition(all 150ms ease);
    height: 1rem;
    width: 1rem;
    margin-left: 1rem;
  }

  // TODO: https://css-tricks.com/using-css-transitions-auto-dimensions/#aa-technique-3-javascript
  &.active {
    .content {
      max-height: 100rem;
    }

    .title svg {
      transform: rotate(90deg);
    }
  }
}

@include breakpoints.tab-sm {
  .anchor {
    top: calc(#{vars.$header-height-desk} * -1);
  }
}

@include breakpoints.desk-xxs {
  .base {
    padding: 0;
  }

  .anchor {
    top: calc(#{vars.$header-height-desk} * -1);
  }
}

@include breakpoints.desk-xs {
  .anchor {
    top: calc(#{vars.$header-height-desk} * -1);
  }
}
