@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "../../layouts/Menu/_vars.scss";

.trigger {
  @include flex.col(center);
  background-color: colors.$dark;
  border-radius: 50%;
  border: 1px solid colors.$grey-dark;
  height: vars.$menu-logo-size;
  overflow: hidden;
  position: relative;
  width: vars.$menu-logo-size;

  &.no_avatar {
    padding: 20%;
    border-style: solid;
    border-width: 1px;
  }

  img {
    height: vars.$menu-logo-size;
    width: vars.$menu-logo-size;
  }

  &:hover {
    border-color: colors.$white;
  }

  &.active {
    border-color: colors.$primary;
  }
}

.content {
  height: auto;
  max-height: 35rem;
  min-height: 25rem;
  padding: 0;
  width: 15rem;

  .items {
    @include flex.col(flex-start, (full: true));

    a, button {
      @include flex.row(flex-start, (full: true, pure: true));
      @include mixins.titleize;
      font-weight: 400;
      color: colors.$white;
      font-size: 1rem;
      cursor: pointer;
      padding: 0.5em 1.5em;

      svg {
        height: 1em;
        width: 1.5em;
        margin-right: 0.5em;
      }

      &:hover {
        background-color: colors.$dark;
      }
    }
  }

  .section_header {
    @include mixins.title-primary;
    font-size: 0.85rem;
    padding: 0.5em 1em;
    margin: 1em 0 0;
  }

  .section {
    @include flex.col(flex-start, (align: flex-start, full: true));
  }

  .section_logout {
    margin: 1em 0 0;

    hr {
      @include flex.col(center, (full: true));
      align-self: center;
      border: 1px solid colors.$dark;
      margin: 0;
    }

    button {
      padding: 1em;
    }
  }

  .Header_items_button {
    height: 1.5em;
    width: 1.5em;
  }
}
