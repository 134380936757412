@use "../../assets/stylesheets/breakpoints.scss";
@use "../../assets/stylesheets/colors.scss";
@use "../../assets/stylesheets/flex.scss";
@use './_vars';

.wrapper {
  @include flex.col(center, (full: true));
  height: vars.$page-hero-height-mobile;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.hero_default {
  .overlay {
    box-shadow: none;
    background-color: darken(colors.$secondary, 45%);
    opacity: 0.8;
  }

  img {
    filter: blur(2px);
  }
}

.wrapper, .wrapper img {
  height: vars.$page-hero-height-mobile;
  object-fit: cover;
  width: 100%;
}

.overlay {
  box-shadow: 0 -3px 5px 0px colors.$darker inset, 0 3px 5px 0px colors.$darker inset;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@include breakpoints.tab-md {
  .wrapper, .wrapper img {
    height: vars.$page-hero-height-tab;
  }
}

@include breakpoints.desk-xs {
  .wrapper, .wrapper img {
    height: vars.$page-hero-height;
  }
}
