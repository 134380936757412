@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";

.buttons {
  @include flex.row(center, (full: true));
  gap: 1rem;
}

.Button {
  gap: 1rem;

  svg {
    height: 1rem;
    width: 1rem;
  }
}

.Content {
  @include flex.col(center, (full: true));
  @include mixins.tapered;
}

.Title {
  color: colors.$white;
}

@include breakpoints.desk-xxs {
  .buttons {
    gap: 0.5rem;
  }
}
