@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../../../../assets/stylesheets/mixins.scss";
@use "../_vars.scss";

:global(.DropdownCardHeader) {
  @include flex.row(space-between, (pure: true, full: true));
  align-items: center;
  height: vars.$dropdown-card-height-short;
  position: relative;

  h3 {
    margin: 0;
    font-size: 0.8em;
  }

  svg {
    @include mixins.transition(150ms);
    height: 1.25rem;
    width: 1.25rem;
  }
}

.actions {
  @include flex.row(flex-end, (pure: true));
}

.activate {
  @include flex.row(space-between, (pure: true, full: true));
  color: colors.$white;
  height: vars.$dropdown-card-height-short;
  padding: 0 1rem;
  text-transform: capitalize;
}

.no_container {
  height: vars.$dropdown-card-height-tall;

  .activate {
    height: vars.$dropdown-card-height-tall;
  }
}
