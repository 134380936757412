@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";

:global(.CommentCard) {
  @include flex.col(flex-start, (align: flex-start, full: true));
  background: lighten(colors.$dark, 5%);
  border-bottom: 3px solid colors.$dark;
  border-radius: 4px;
  font-size: 0.85em;
  line-height: 1.2;
  padding: 1rem;
}

.content {
  color: colors.$white;
}

.user_created {
  @include flex.row(space-between, (pure: true, full: true, wrap: true));
  color: colors.$grey;
  gap: 0 1rem;
  padding-bottom: 0.5rem;
}

.user {
  @include flex.row(center, (pure: true));

  &:hover {
    text-decoration: underline;
  }
}
