/**********
  This is a mobile-first stylesheet.
  Design for mobile first, and treat larger viewports as the exception.
**********/

// Cheatsheets for device sizes:
// https://www.mydevice.io/#compare-devices
// https://www.w3schools.com/browsers/browsers_display.asp

// Phone
$device-mobile-xs: 320px;
$device-mobile-sm: 360px;
$device-mobile-md: 375px;
$device-mobile-lg: 411px;
$device-mobile-xl: 480px;

// Tablet
$device-tablet-sm: 600px;
$device-tablet-md: 720px;
$device-tablet-lg: 1024px;

// Desktop
$device-desktop-xxs: 1024px;
$device-desktop-xs: 1280px;
$device-desktop-sm: 1440px;
$device-desktop-md: 1680px;
$device-desktop-lg: 1920px;
$device-desktop-xl: 2560px;
$device-desktop-xxl: 3360px;

@mixin portrait-query($query-width) {
  @media (min-device-width: #{$query-width}) and (orientation: portrait) {
    @content;
  }
}

@mixin landscape-query($query-width) {
  @media (min-device-width: #{$query-width}) and (orientation: landscape) {
    @content;
  }
}

@mixin mob-xs {
  // @include portrait-query($device-mobile-xs) { @content };
  @media (min-device-width: #{$device-mobile-xs}) { @content };
}

@mixin mob-sm {
  // @include portrait-query($device-mobile-sm) { @content };
  @media (min-device-width: #{$device-mobile-sm}) { @content };
}

@mixin mob-md {
  // @include portrait-query($device-mobile-md) { @content };
  @media (min-device-width: #{$device-mobile-md}) { @content };
}

@mixin mob-lg {
  // @include portrait-query($device-mobile-lg) { @content };
  @media (min-device-width: #{$device-mobile-lg}) { @content };
}

@mixin mob-xl {
  // @include portrait-query($device-mobile-xl) { @content };
  @media (min-device-width: #{$device-mobile-xl}) { @content };
}

@mixin tab-sm {
  // @include portrait-query($device-tablet-sm) { @content };
  @media (min-device-width: #{$device-tablet-sm}) { @content };
}

@mixin tab-md {
  // @include portrait-query($device-tablet-md) { @content };
  @media (min-device-width: #{$device-tablet-md}) { @content };
}

@mixin tab-lg {
  // @include portrait-query($device-tablet-lg) { @content };
  @media (min-device-width: #{$device-tablet-lg}) { @content };
}

@mixin tab-lg-landscape {
  @include landscape-query($device-tablet-lg) { @content };
}

// Can be landscape landscape OR portrait
@mixin desk-xxs {
  @media (min-device-width: #{$device-desktop-xxs}) { @content };
}

@mixin desk-xs {
  // @include landscape-query($device-desktop-xs) { @content };
  @media (min-device-width: #{$device-desktop-xs}) { @content };
}

@mixin desk-sm {
  // @include landscape-query($device-desktop-sm) { @content };
  @media (min-device-width: #{$device-desktop-sm}) { @content };
}

@mixin desk-md {
  // @include landscape-query($device-desktop-md) { @content };
  @media (min-device-width: #{$device-desktop-md}) { @content };
}

@mixin desk-lg {
  // @include landscape-query($device-desktop-lg) { @content };
  @media (min-device-width: #{$device-desktop-lg}) { @content };
}

@mixin desk-xl {
  // @include landscape-query($device-desktop-xl) { @content };
  @media (min-device-width: #{$device-desktop-xl}) { @content };
}

@mixin desk-xxl {
  // @include landscape-query($device-desktop-xxl) { @content };
  @media (min-device-width: #{$device-desktop-xxl}) { @content };
}
