@use "../../../../assets/stylesheets/colors.scss";

.react-datepicker {
  border-color: colors.$grey-dark;
  background-color: colors.$dark;
  color: colors.$white;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__triangle {
  transform: translate3d(28px, 0px, 0px) !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-bottom-color: colors.$dark;
}

.react-datepicker__header {
  background-color: colors.$dark;
  border-bottom: 1px solid colors.$dark;
}

.react-datepicker__current-month {
  color: white;
}

.react-datepicker__day-name {
  color: white;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: white;
}

.react-datepicker__time-container .react-datepicker__time {
  background-color: colors.$darker;
  color: white;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: white;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  color: black;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background: white;
  color: black;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background-color: colors.$grey-lighter;
  color: black;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background: white;
  color: black;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background: white !important;
  color: black;
}

.datetime-pickers {
  label {
    color: #bdbdbd;
    font-size: 0.8em;
    margin-bottom: 0.5rem;
  }
}

.react-datepicker__input-container input {
  background-color: colors.$dark;
  border-radius: 4px;
  border: 1px solid colors.$grey-dark;
  color: white;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  width: 100%;

  &:focus {
    outline: 1px solid colors.$grey-light;
  }
}

.react-datepicker__navigation-icon {
  font-size: 10px;
}

.react-datepicker__navigation-icon::before {
  border-width: 2px 2px 0 0;
  height: 7px;
  top: 4px;
  width: 7px;
}
