@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../../../../assets/stylesheets/spacing.scss";

.base {
  @include flex.col(center, (full: true));
}

.content {
  padding: 0;
  gap: 4rem
}
