@use "../../../../../assets/stylesheets/colors.scss";

.MediaUploader__uppy {
  .uppy-Root {
    height: 100%;
    width: 100%;
  }

  button.uppy-DragDrop-container {
    background-color: colors.$dark;
    height: 100%;
    width: 100%;

    .uppy-DragDrop-inner {
      padding: 0;
    }

    &:hover, &:focus {
      background: lighten(colors.$dark, 2%);

      .uppy-DragDrop-label {
        color: colors.$white;
      }

      .uppy-DragDrop-browse {
        color: colors.$primary;
      }
    }

    &:focus {
      outline-color: colors.$primary !important;
    }
  }

  .uppy-DragDrop-arrow {
    // fill: colors.$primary;
    fill: transparent;
    height: 2rem;
    width: 2rem;

    path {
      stroke: colors.$primary
    }
  }

  .uppy-DragDrop-label {
    color: colors.$grey;
    font-size: 0.85em;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .uppy-DragDrop--isDragDropSupported {
    border: 1px dashed colors.$grey;
  }

  .uppy-DragDrop--isDraggingOver {
    border: 1px dashed colors.$primary;

    .uppy-DragDrop-arrow {
      fill: colors.$primary;

      path {
        stroke: colors.$primary
      }
    }
  }

  .uppy-DragDrop-browse {
    color: colors.$grey;
  }
}
