@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../../../../assets/stylesheets/mixins.scss";

.base {
  align-items: stretch;
  // max-width: unset;
  @include mixins.tapered;
}

.cover_container {
  @include flex.col(center, (full: true));
}

.buttons {
  @include flex.col(flex-end, (align: flex-end, full: true));
  margin: 1rem 0 0;
}

@include breakpoints.desk-sm {
  .cover_container {
    height: 33rem;
    max-height: unset;
    width: 33rem;
  }
}
