@use "../../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../../assets/stylesheets/colors.scss";
@use "../../../../../assets/stylesheets/flex.scss";

.votes {
  @include flex.row(space-between, (pure: true));
  gap: 1rem;
}

@include breakpoints.desk-xxs {
  .votes {
    flex-direction: column;
    justify-content: space-between;
    width: 1.5rem;
    gap: 0.5rem;
  }

  .vote_count {
    color: colors.$grey;
    margin: 0.5rem 0;
  }
}
