@use "../../../../../assets/stylesheets/flex.scss";

.inputs-wrapper {
  @include flex.row(flex-start, (full: true));
}

.inputs {
  @include flex.col(flex-start, (full: true));
  position: relative;
  gap: 1rem;
}
