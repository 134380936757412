@use "../../../../../assets/stylesheets/colors.scss";
@use "../../../../../assets/stylesheets/flex.scss";

.input-container {
  @include flex.row(flex-start, (full: true));
  position: relative;
  color: colors.$white;

  input + svg {
    color: lighten(colors.$grey, 15%);
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(50%);
    width: 1rem;
  }
}

.input-container > input {
  background: transparent;
  border: none;
  border-bottom: 1px solid lighten(colors.$grey, 15%);
  color: lighten(colors.$grey, 15%);
  font-size: 1em;
  padding: 0.5rem 0;
  width: 100%;

  &:focus {
    border-bottom: 1px solid colors.$white;
    color: colors.$white;
    outline: none;
  }
}
