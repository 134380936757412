@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../../../../assets/stylesheets/mixins.scss";

.contentWrapper {
  @include flex.col(center, (full: true));
  border-radius: 0;
  overflow: hidden;
}

.header {
  @include flex.row(center, (full: true));
  display: none;
  padding: 0 1rem;
  text-align: center;
  gap: 0.5rem
}

.headerCol {
  @include mixins.title-primary;
  color: colors.$primary;
  font-size: 0.75em;
}

@include breakpoints.desk-xxs {
  .contentWrapper {
    border-radius: 6px;
    border: 1px solid colors.$grey-dark;
  }

  .header {
    display: flex;
    text-align: left;
  }
}
