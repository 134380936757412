@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";

:global(.MessagesUtilMenu) {
  @include flex.col(center, (full: true));

  :global(.Cards) {
    gap: 0;
  }
}
