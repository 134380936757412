@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "./_vars.scss";

.wrapper {
  @include flex.row(flex-start, (full: true));
  align-items: flex-start;
  background-color: colors.$dark;
  border-bottom: 1px solid colors.$grey-dark;
  border-radius: 0;
  color: colors.$white;
  text-align: left;
}

.col {
  @include flex.row(flex-start, (align: flex-start, pure: true, wrap: true));
  margin-bottom: 0.5rem;
  text-align: left;
}

.container {
  @include flex.row(space-between, (full: true));
  height: 100%;
  padding: 1rem;
  gap: 1rem;
}

.header {
  @include flex.row(space-between, (pure: true));
}

.description {
  color: colors.$grey;
}

.logo {
  height: vars.$avatar-size;
  width: vars.$avatar-size;

  img {
    height: vars.$avatar-size;
    width: vars.$avatar-size;
  }
}

.categories {
  @include flex.row(flex-start, (pure: true, wrap: true));
  gap: 0.25rem;
}

.rewards {
  @include flex.col(center);
}

.actions {
  @include flex.row(center, (pure: true));
}

.action {
  @include flex.row(center);
  border-radius: 0.5em;
  border: 1px solid;
  color: black;
  height: fit-content;
  margin: 0 0.25em;
  padding: 0.5em;

  svg {
    height: 1em;
    width: 1em;
  }
}

.action.edit {
  color: colors.$grey-light;
  border-color: colors.$grey-light;

  &:hover {
    background-color: colors.$grey-light;
    color: colors.$dark;
  }
}

.action.delete {
  color: colors.$red;
  border-color: colors.$red;

  &:hover {
    background-color: colors.$red;
    color: colors.$dark;
  }
}

.CardAdmin {
  border-width: 0 0 1px 0;
}

@include breakpoints.tab-sm {
  .CardAdmin {
    border: 1px solid colors.$grey-dark;
  }
}

@include breakpoints.desk-xxs {
  .actions {
    gap: 0.2em;
  }

  .action {
    font-size: 1em;
  }

  .col {
    font-size: 0.75em;
    margin-bottom: 0;
  }

  .name {
    @include mixins.ellipsis-clamp(2);
  }

  .description {
    @include mixins.ellipsis-clamp(3);
  }

  .rewards {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@include breakpoints.desk-xs {
  .action {
    font-size: 0.8rem;
  }
}

@include breakpoints.desk-sm {
  .action {
    font-size: 1rem;
  }

  .col {
    font-size: 0.75rem;
  }
}

@include breakpoints.desk-md {
  .action {
    font-size: 1rem;
  }

  .col {
    font-size: 0.9rem;
  }
}
