$utility-menu-height-mobile: 4rem;
// $utility-menu-width-desktop: 30rem;
$utility-menu-width-desktop-xxs: 16rem;
$utility-menu-width-desktop-xs: 20rem;
$utility-menu-width-desktop-sm: 20rem;
$utility-menu-width-desktop-lg: 20rem;
// $utility-menu-width-desktop-sm: 24rem;
// $utility-menu-width-desktop-lg: 25rem;
$logo-size: 3rem;
$img-size: 1.25rem;
