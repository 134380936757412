@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "../DropdownCard/vars.scss";

.wrapper {
  @include flex.col(flex-start, (full: true));
  max-height: calc(25rem - #{vars.$dropdown-input-height});
  overflow: scroll;
  padding: 0.5rem 0;
}

.item {
  @include mixins.titleize;
  border-radius: 4px;
  color: colors.$grey-light;
  font-size: 0.85rem;
  font-weight: 400;
  padding: 0.5rem;
  text-align: left;
  width: 100%;

  &:hover, &:focus {
    background-color: colors.$grey-lightest;
    background-color: colors.$white;
    color: colors.$dark;
    cursor: pointer;
    font-weight: 600;
  }

  &:disabled {
    display: none;
  }
}

@include breakpoints.desk-sm {
  .wrapper {
    max-height: calc(30rem - #{vars.$dropdown-input-height});
  }
}
