@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";

.base {

}

.file {
  margin-bottom: 1rem;
  width: 100%;
}
