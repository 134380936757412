@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/components.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "./_vars.scss";

.base {
  @include components.responsive-container;
  @include flex.row(space-between, (full: true, pure: true));
  background-color: colors.$darker;
  padding: vars.$header-padding-vert-mobile vars.$header-padding-horz-mobile;
  gap: 0.5rem;
  height: vars.$header-height-mobile;
  position: sticky;
  border-bottom: 1px solid colors.$grey-darker;
  top: 0;
  z-index: 3;

  h2 {
    @include mixins.title-primary;
    font-size: 1em;
    margin: 0;
  }
}

.logo {
  height: vars.$header-height-mobile;
  width: vars.$header-logo-width-mobile;
  padding: 0.5rem 0;

  img {
    height: 100%;
  }
}

.user-buttons {
  @include flex.row(flex-end, (pure: true));
  gap: vars.$user-buttons-gap-mobile;
  // padding: 0 vars.$user-buttons-padding;
  width: vars.$user-buttons-width-desk;

  .button {
    white-space: nowrap;
  }

  .login {
    letter-spacing: 1;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: 500;
    // margin: 0 2rem;
  }
}

@include breakpoints.desk-xxs {
  .base {
    // padding: vars.$header-padding-vert-desk 2rem;
    gap: 1rem;
    height: vars.$header-height-desk;
    border-width: 0;

    .logo {
      display: none;
    }

    .items-links {
      display: flex;
    }

    &.scrolled {
      border-width: 1px;
    }
  }

  .user-buttons {
    gap: vars.$user-buttons-gap-desk;
    width: vars.$user-buttons-width-desk;
  }
}

@include breakpoints.desk-sm {
  .base {
    padding: vars.$header-padding-vert-desk 2rem;
  }
}
