@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";

.base {
  align-items: stretch;
  margin-bottom: 2rem;
  max-width: unset;
  padding: 0 1rem;
}

.AvatarButton {
  margin-bottom: 2rem;
}

.ProfileForm__cover__dropdown {
  @include flex.row(space-between, (pure: true, full: true));
  border-bottom: 1px solid;

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.ProfileForm__cover_container, .ProfileForm__avatar_container {
  @include flex.col(center, (full: true));

  label {
    color: colors.$grey-light;
    font-size: 0.85em;
    margin-bottom: 0.5rem;
  }
}

.ProfileForm__cover {
  display: flex;
  width: 100%;

  .AvatarButton {
    width: 100%;
    height: 100%;
  }
}

.bio {
  max-width: 100%;
  min-width: unset;
}

.ProfileForm__details {
  width: 100%;
}

.ProfileForm__buttons {
  @include flex.col(flex-end, (align: flex-end, full: true));
  margin: 1rem 0 0;
}

@include breakpoints.desk-xxs {
  .ProfileForm {
    .AvatarButton {
      margin-bottom: 0;
    }

    .ProfileForm__cover {
      .ProfileForm__cover_container {
        max-height: 25rem;
      }
    }

    .ProfileForm__details, .ProfileForm__cover {
      width: 49%;
    }

    .ProfileForm__avatar_container {
      width: auto;
    }
  }

  textarea#ProfileForm__bio {
    max-width: 100%;
    width: 100%;
  }
}

@include breakpoints.desk-sm {
  .ProfileForm {
    .ProfileForm__cover {
      .ProfileForm__cover_container {
        height: 33rem;
        max-height: unset;
        width: 33rem;
      }
    }

    .ProfileForm__details, .ProfileForm__cover {
      width: auto;
    }
  }
}
