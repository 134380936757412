@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";

.header {
  @include flex.row(space-between, (full: true));
  align-items: flex-start;
}

.subheader {
  @include flex.col(space-between, (full: true));
  align-items: flex-start;
  gap: 0.5rem;
  padding: 0.5rem 0;
}

.content, .content_wrapper {
  @include flex.col(center, (full: true));
  height: 100%;
}

.content {
  gap: 0.5rem
}

.actions {
  @include flex.row(center, (full: true, pure: true));
  align-items: center;
  font-size: 1.25rem;
  justify-content: space-between;
}

.user_time {
  @include flex.row(flex-end, (full: true, pure: true));
  margin-bottom: 0;
  gap: 0.5rem;
}

@include breakpoints.tab-sm {
  .actions {
    font-size: 1em;
  }
}

@include breakpoints.desk-xxs {
  .user_time {
    width: auto;
  }
}
