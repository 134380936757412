@use "../../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../../assets/stylesheets/colors.scss";
@use "../../vars.scss";

.textarea {
  background-color: colors.$dark;
  border-radius: 4px;
  color: colors.$white;
  height: 5rem;
  padding: 1rem;
  max-width: vars.$form-width-sm;
  width: 100%;

  &:focus {
    outline: 1px solid colors.$grey;
  }
}

@include breakpoints.desk-xs {
  .textarea {
    max-width: vars.$form-width-lg;
  }

  .textarea {
    max-width: unset;
  }
}
