@use "../Header/_vars.scss";

// $page-title-margin-left-mobile: 1rem;
// $page-title-max-width: calc(100dvw - #{$header-padding-horz-mobile} * 2 - #{$header-logo-width-mobile} - #{$page-title-margin-left-mobile} - #{$user-buttons-width});
$page-title-max-width-mobile: calc(
  100dvw
  - #{vars.$header-padding-horz-mobile} * 2
  - #{vars.$header-logo-width-mobile}
  - #{vars.$user-buttons-width-mobile}
);

$page-title-max-width-desk: calc(
  100dvw
  - #{vars.$header-padding-horz-mobile} * 2
  - #{vars.$header-logo-width-mobile}
  - #{vars.$user-buttons-width-desk}
);
