@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../pages/admin/categories/List/_vars.scss";

@include breakpoints.desk-xxs {
  .name {
    width: vars.$name-col-width;
  }

  .relevance {
    width: vars.$relevance-col-width;
  }

  .actions {
    width: vars.$actions-col-width;
  }
}
