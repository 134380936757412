@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../../../../assets/stylesheets/mixins.scss";
@use "../../../../pages/admin/rules/List/_vars.scss";

.content {
  @include mixins.ellipsis-clamp(4);
}

@include breakpoints.desk-xxs {
  .categories {
    margin-left: 1rem;
  }

  .title {
    width: vars.$title-col-width;

    a:hover {
      text-decoration: underline;
    }
  }

  .content {
    width: vars.$content-col-width;
  }

  .category {
    width: vars.$category-col-width;
  }

  .actions {
    width: vars.$actions-col-width;
  }
}
