@use "../../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../../assets/stylesheets/mixins.scss";

.title {
  @include mixins.ellipsis-clamp(2);

  &:hover {
    text-decoration: underline;
  }
}

@include breakpoints.desk-xxs {
  .title {
    margin-right: 1rem;
  }
}
