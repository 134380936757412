// $menu-height-lg: 5rem;
// $menu-height-md: 5rem;
// $menu-height-sm: 4rem;
$menu-height-mobile: 4rem;

$menu-height-lg: 0rem;
$menu-height-md: 0rem;
$menu-height-sm: 0rem;
$menu-height: 3rem;
$menu-logo-width: 100%;
$menu-logo-size: 2.5rem;
$menu-padding-vert-sm: 1rem;
$menu-padding-vert-lg: 2rem;
$menu-width-desktop: 4rem;

$menu-link-size: 2rem;
