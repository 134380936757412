@use "../../../../../assets/stylesheets/colors.scss";

.base {
  color: colors.$grey;

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.upvote {
  &:hover, &:focus, &.active {
    color: colors.$primary;
  }
}

.downvote {
  &:hover, &:focus, &.active {
    color: colors.$secondary;
  }
}
