@use "../../../assets/stylesheets/flex.scss";

.error-conainer {
  @include flex.col(center);
  flex-grow: 1;
  margin: 4rem 0;

  img {
    width: 100%;
    max-width: 40rem;
  }
}
