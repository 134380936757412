@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "../Menu/vars.scss" as menuVars;
@use "./_vars.scss";

.App {
  @include flex.row(center);
  // height: $view-height-mobile-with-menu;
  height: 100vh;
  overflow: hidden;
  @include mixins.transition(150ms);
}

.modal-active {
  filter: blur(15px);
  transform: scale(0.95);
}

main.wrapper {
  @include mixins.fill-available-height;
  background: -webkit-linear-gradient(colors.$darker 60% , rgb(91, 64, 255, 0.05) 100%);
  // background: -webkit-linear-gradient(colors.$darker 60%, rgb(15, 25, 35, 0.5) 100%);
  min-height: calc(100dvh - #{menuVars.$menu-height});
  overflow: scroll;
  width: 100%;
  z-index: 1;
}

.container {
  @include flex.col(flex-start, (full: true));
  position: relative;
  min-height: 100vh;
}

@include breakpoints.desk-xxs {
  .App {
    height: vars.$view-height-desktop;
  }

  main.wrapper {
    height: vars.$view-height-desktop;
  }

  .container {
    max-width: calc(100vw - #{menuVars.$menu-width-desktop});
  }
}
