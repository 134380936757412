@use "../../../../assets/stylesheets/breakpoints.scss";
@use "./_vars.scss";

@include breakpoints.desk-xxs {
  .title {
    width: vars.$title-col-width;
  }

  .content {
    width: vars.$content-col-width;
  }

  .category {
    width: vars.$category-col-width;
  }

  .actions {
    width: vars.$actions-col-width;
  }
}
