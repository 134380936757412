@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../../../../assets/stylesheets/mixins.scss";

.clear {
  @include mixins.transition(150ms background-color);
  border-radius: 2rem;
  border: 1px solid colors.$grey-light;
  color: colors.$grey-light;
  cursor: pointer;
  font-size: 0.6em;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 0.5rem 1rem;
  position: absolute;
  right: 4rem;
  text-transform: uppercase;
  width: auto;

  &:hover, &:focus {
    background-color: colors.$grey-light;
    color: colors.$black;
    font-weight: 600;
  }
}
