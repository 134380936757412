@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";

.content {
  @include flex.col(flex-start, (align: flex-start, full: true));
  gap: 1.5rem;
}

.title {
  font-size: 1.25em;
  overflow: hidden;
  margin-bottom: 1rem;
}

.bio {
  color: colors.$grey;
  line-height: 1.25;
  overflow: hidden;
}

.body {
  justify-content: flex-start;
}

.categories {
  @include flex.row(flex-end, (full: true, pure: true, wrap: true));
  gap: 0.25rem;
}

.message:hover {
  background-color: colors.$white;
  color: colors.$dark;
}
