@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../../../../assets/stylesheets/fonts.scss";
@use "../../../../assets/stylesheets/mixins.scss";

.container {
  @include flex.col(center, (full: true));
  display: none;
  margin: 0 0 2rem;
  position: relative;
}

.form {
  margin: 0;
  padding: 0 1rem;
  width: 100%;

  .input {
    background-color: colors.$dark;
    border-radius: 4px;
    border: 1px solid colors.$grey-dark;
    color: colors.$white;
    font-size: 1em;
    font-weight: fonts.$font-weight-light;
    height: 3rem;
    // max-width: 50rem;
    @include mixins.tapered;
    outline: none;
    text-indent: 3rem;
    width: 100%;

    &:hover, &:focus {
      border-color: colors.$grey-light;
    }
  }


  .svg {
    color: darken(colors.$grey, 10%);
    font-size: 1em;
    height: 1.25rem;
    left: 2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1.25rem;
  }

  &:focus-within {
    .svg {
      color: colors.$white;
      font-weight: 500;
    }
  }
}

@include breakpoints.desk-xxs {
  .container {
    display: flex;
  }
}
