@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../../../../assets/stylesheets/mixins.scss";
@use "../../../../assets/stylesheets/spacing.scss";
@use "../../../../pages/admin/leagues/List/_vars.scss";

.members {
  @include mixins.ellipsis-clamp(2);
}

.name a:hover {
  text-decoration: underline;
}

.categories {
  gap: 0.25rem;
}

@include breakpoints.desk-xxs {
  .logo {
    width: vars.$logo-col-width;
  }

  .name {
    width: vars.$name-col-width;
  }

  .description {
    width: vars.$description-col-width;
  }

  .members {
    width: vars.$members-col-width;
  }

  .categories {
    width: vars.$categories-col-width;
  }
}
