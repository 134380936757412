@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";

:global(.MyStuffUtilMenu) {
  @include flex.col(flex-start, (full: true));
  height: 100%;
  gap: 0.25rem;
}

.link {
  @include flex.row(flex-start, (full: true));
  @include mixins.titleize;
  border-radius: 0.5rem;
  color: colors.$grey;
  font-size: 1em;
  margin: 0 0 0.25rem;
  padding: 0.75rem 1rem;

  &:hover {
    color: colors.$grey;
    background-color: lighten(colors.$darker, 5%);
  }

  &:global(.active) {
    color: colors.$grey-light;
    background-color: colors.$darker;
    pointer-events: none;
    font-weight: 600;
  }
}
