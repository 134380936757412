@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";

.base {
  align-self: flex-start;
  border-bottom-color: colors.$secondary;
  border-radius: 0.5rem;
  max-width: 90%;
  padding: 0.5rem 1rem 1rem;
  width: auto !important;

  &:hover {
    border-color: colors.$secondary;
    background-color: lighten(colors.$dark, 5%);

    .user {
      max-width: 20rem;
    }

    .time {
      color: colors.$grey-light;
    }

    .dots {
      height: 1.25rem;
      opacity: 1;

      &:hover {
        color: colors.$white;
      }
    }
  }
}

.content {
  // justify-content: flex-end;
  // justify-content: flex-start;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.header {
  @include flex.row('flex-end');
  height: 1.25rem;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin: 0;
  gap: 0.5rem;
  overflow: hidden;
}

.user {
  display: none;
}

.time {
  // padding-left: 1rem;
  font-size: 0.75rem;
  font-weight: 300;
  color: colors.$grey-dark;
  line-height: 1;
  margin: 0;
}

.dots {
  color: colors.$grey-dark;
  cursor: pointer;
  display: initial;
  height: 1.25rem;
  width: 1rem;
  height: 0;
  opacity: 0;
}

.self_authored {
  align-self: flex-end;
  border-bottom-color: colors.$primary;

  .header {
    flex-direction: row;
  }

  .content {
    justify-content: flex-start;
  }

  &:hover {
    border-bottom-color: colors.$primary;
  }
}

.user {
  @include flex.row(center, (pure: true));
  background-color: colors.$darkest;
  border-radius: 0.25rem;
  box-shadow: -1px -1px 12px -6px colors.$dark, 0 0 1px 1px colors.$grey-dark;
  display: flex;
  padding: 1rem;
  z-index: 8;

  .user_link {
    @include flex.row(center, (pure: true));
    outline: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
