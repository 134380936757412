@use "../../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../../assets/stylesheets/colors.scss";

.base {
  color: colors.$grey;

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }

  &:hover, &.active {
    color: colors.$primary;
  }
}


@include breakpoints.desk-xxs {
  .base {
    margin-left: 0;

    &:focus {
      color: colors.$primary;
    }
  }
}
