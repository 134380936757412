@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../../../../assets/stylesheets/mixins.scss";
@use "./vars";

:global(.ContactCard) {
  @include flex.col(center, (align: flex-start, full: true));
  @include mixins.titleize;
  background-color: colors.$darkest;
  border-radius: 0;
  color: colors.$grey;
  padding: 1rem;
  border-top: none;

  &:hover {
    // background-color: colors.$dark;
    color: colors.$grey-light;
    border-color: colors.$grey-light;
    box-shadow: none;
  }

  &:global(.active) {
    // background: -webkit-linear-gradient(transparent 10%, colors.$dark 100%);
    // background: -webkit-linear-gradient(transparent 40%, #a0d34113 90%, #a0d34149 100%);
    // border-bottom: 1px solid colors.$grey-dark;
    // box-shadow: 3px 0 1px -1px colors.$primary inset;
    // filter: brightness(120%);
    background-color: colors.$dark;
    border-color: colors.$primary;
    border-width: 0 0 1px 0;
    color: colors.$primary;
    font-weight: 600;
    pointer-events: none;
  }
}


.header {
  @include flex.row(flex-start, (full: true));
}

.avatar {
  border-radius: 50%;
  height: vars.$avatar-size;
  margin-right: 1rem;
  overflow: hidden;
  width: vars.$avatar-size;

  img {
    height: vars.$avatar-size;
    width: vars.$avatar-size;
  }
}

.title {
  @include mixins.ellipsis-clamp(2);
  font-size: 0.75rem;
}

@include breakpoints.desk-sm {
  .title {
    font-size: 1rem;
  }
}
