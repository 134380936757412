@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../../../../assets/stylesheets/mixins.scss";
@use "../../../../assets/stylesheets/spacing.scss";
@use "../../../../pages/admin/users/List/_vars.scss";

.name a:hover {
  text-decoration: underline;
}

.email {
  @include mixins.ellipsis-clamp(1);
  line-break: anywhere;
}

.bio {
  @include mixins.ellipsis-clamp(2);
  color: colors.$grey;
}

.categories {
  gap: 0.25rem;
}

@include breakpoints.desk-xxs {
  .logo {
    width: vars.$avatar-col-width;
  }

  .name {
    width: vars.$name-col-width;
  }

  .email {
    width: vars.$email-col-width;
  }

  .bio {
    width: vars.$bio-col-width;
  }

  .categories {
    width: vars.$categories-col-width;
  }
}
