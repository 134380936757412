@use "./breakpoints.scss";

@mixin flex($direction: column, $justify: center, $options: ()) {
  // $align: map-get($options, align);

  // align-items: $align;
  display: flex;
  // flex-direction: if(map-get($options, pure), $direction, column);
  justify-content: $justify;
  // width: max-content;
  // width: auto;

  @if map-has-key($options, align) {
    align-items: map-get($options, align);
  } @else {
    align-items: center;
  }

  @if map-has-key($options, pure) {
    flex-direction: $direction;
  } @else {
    flex-direction: column;
  }

  @if map-has-key($options, full) {
    // @error map-get($options, full);
    width: 100%;
  }

  @if map-has-key($options, wrap) {
    flex-wrap: wrap;
  }

  @include breakpoints.desk-xxs {
    flex-direction: $direction;
  }
}

@mixin col($justify: center, $options: ()) {
  @include flex(column, $justify, $options);
}

@mixin row($justify: center, $options: ()) {
  @include flex(row, $justify, $options);
}
