@use "../../assets/stylesheets/breakpoints.scss";
@use "../../assets/stylesheets/colors.scss";
@use "../../assets/stylesheets/flex.scss";
@use "../../assets/stylesheets/fonts.scss";
@use "../../assets/stylesheets/mixins.scss";
@use "./_vars.scss";

.button {
  @include flex.row(center, (pure: true));
  border-radius: 1rem;
  border: 1px solid colors.$grey;
  cursor: pointer;
  height: vars.$avatar-size;
  min-height: vars.$avatar-size;
  min-width: vars.$avatar-size;
  overflow: hidden;
  position: relative;
  width: vars.$avatar-size;

  // From cover form
  height: 20rem;
  margin-bottom: 2rem;
  max-height: unset;
  max-width: unset;
  width: 100%;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &:hover {
    border-color: colors.$white;
  }

  .editOverlay {
    @include flex.col(center);
    @include mixins.transition(100ms);
    background: rgba(0, 0, 0, 0.75);
    border-radius: 0.5rem;
    color: colors.$white;
    font-size: 0.75em;
    font-weight: fonts.$font-weight-normal;
    height: 100%;
    left: 0;
    letter-spacing: 1px;
    opacity: 0;
    position: absolute;
    text-transform: uppercase;
    top: 0;
    visibility: hidden;
    width: 100%;

    &--active {
      opacity: 1;
      visibility: visible;
    }
  }

  :global(.UploadForm) {
    height: 20rem;
  }
}

.base {
  border-radius: vars.$user-avatar-size;
  height: vars.$user-avatar-size;
  margin-right: 0.5rem;
  overflow: hidden;
  width: vars.$user-avatar-size;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.currentImage {
  @include flex.col(center);
  cursor: pointer;
  height: 100%;
  position: relative;
  width: 100%;

  .currentImageOverlay {
    @include flex.col(center);
    @include mixins.transition(100ms);
    background-color: colors.$black;
    font-size: 0.7em;
    font-weight: 500;
    height: 100%;
    left: 0;
    letter-spacing: 1px;
    opacity: 0;
    padding: 0.5rem;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 0;
    width: 100%;

    &.active {
      opacity: 0.75;
    }
  }
}

// From cover form
@include breakpoints.desk-xxs {
  .button {
    height: 30rem;
    margin-bottom: 0;

    :global(.UploadForm) {
      height: 30rem;
    }
  }
}
