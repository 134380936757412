@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../../../../assets/stylesheets/mixins.scss";
@use "../../../../assets/stylesheets/spacing.scss";
@use "../../../layouts/Header/_vars.scss";

$desk-height: calc(100vh - (#{spacing.$padding-layout-vert-sm} * 2) - #{vars.$header-height-desk});

:global(.SubmissionCardExpanded) {
  background: transparent;
  border: none;
  flex-shrink: 0;

  .header {
    flex-direction: column;
    padding: 0.5rem;
    gap: 0.5rem;
  }

  .subheader {
    padding: 0.5rem;
  }

  .favorite_title {
    @include flex.row(flex-start, (pure: true));
    gap: 0.5rem;
  }

  .content_wrapper {
    flex-direction: column-reverse;
  }

  .actions {
    flex-direction: row-reverse;
    height: 2rem;
    gap: 2rem;
  }

  .votes {
    @include flex.row(space-between, (pure: true));
    gap: 1rem;
    width: 100%;
  }

  .favorite {
    margin: 0;
  }

  .user_time {
    flex-direction: row-reverse;
  }

  .actions_wrapper {
    @include flex.row(flex-start, (pure: true));
    align-items: center;
    align-self: flex-end;
    gap: 1rem;
    flex-direction: row-reverse;
    padding: 0.5rem 0;
    width: 100%;

    .actions {
      width: auto;
    }
  }

  &:hover {
    box-shadow: none;
  }
}

@include breakpoints.desk-xxs {
  :global(.SubmissionCardExpanded) {
    height: $desk-height;

    .header {
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      padding: 0;
    }

    .subheader {
      flex-direction: row;
      padding: 0;
    }

    .content_wrapper {
      flex-direction: column;
    }

    .user_time {
      flex-direction: row;
    }

    .league {
      text-align: right;
      width: 20%;
    }

    .favorite_title {
      width: 80%;
    }

    .actions_wrapper {
      flex-direction: row-reverse;
      height: 2rem;
      margin-right: 0;
      padding: 0;
      width: fit-content;
    }

    .actions {
      margin-top: 0;
      font-size: 1em;
    }
  }
}
