@use "../../assets/stylesheets/flex.scss";

.base {
  @include flex.row(center, (pure: true));
  font-weight: 600;

  img {
    margin-right: 0.5rem;
    width: 1.25rem;
    // opacity: 0.5;
    cursor: help;
  }
}
