@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/components.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/spacing.scss";

#base {
  min-height: unset;
}

.hero {
  background-color: darken(colors.$darker, 2%);
  box-shadow: 0 -20px 30px -5px colors.$darker inset, 0 20px 30px -5px colors.$darker inset;
}

.places {
  @include flex.row(space-between, (pure: true, full: true));
  height: 100%;
  max-width: spacing.$max-content-width;
  padding: 1rem;
}

.place_container {
  @include flex.col(center);
  width: 30%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.place {
  position: relative;
  border-radius: 50%;
  border: 1px solid;
  overflow: hidden;
  // box-shadow: -2rem 0 0 10px 10px white;
}

.place-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 50%;
}

.gold {
  height: 13dvh;
  width: 13dvh;
  border-color: colors.$gold;
  box-shadow: 0 0 6px 0px colors.$gold;

  .place-overlay {
    box-shadow: 0 -12px 15px -10px colors.$gold inset;
  }
}

.silver {
  height: 10dvh;
  width: 10dvh;
  border-color: #C0C0C0;
  box-shadow: 0 0 6px 0px #C0C0C0;

  .place-overlay {
    box-shadow: 0 -12px 15px -10px #C0C0C0 inset;
  }
}

.bronze {
  height: 9dvh;
  width: 9dvh;
  border-color: #CD7F32;
  box-shadow: 0 0 6px 0px #CD7F32;

  .place-overlay {
    box-shadow: 0 -12px 15px -10px #CD7F32 inset;
  }
}

@include breakpoints.desk-xxs {
  .gold {
    height: 12rem;
    width: 12rem;
  }

  .silver {
    height: 9rem;
    width: 9rem;
  }

  .bronze {
    height: 8rem;
    width: 8rem;
  }
}
