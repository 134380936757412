@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../_vars.scss";

:global(.DropdownCardContent) {
  @include flex.col(flex-start);
  cursor: default;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.input {
  background-color: colors.$darker;
  border-radius: 4px;
  border: 1px solid colors.$grey-dark;
  color: colors.$grey-light;
  height: vars.$dropdown-input-height;
  padding: 1rem;
  width: 100%;

  &:focus {
    outline: 1px solid colors.$grey-light;
  }
}
