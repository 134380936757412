@use "../../assets/stylesheets/colors.scss";
@use "../../assets/stylesheets/flex.scss";
@use "../../assets/stylesheets/fonts.scss";
@use "../../assets/stylesheets/mixins.scss";

.wrapper {
  @include mixins.transition(0s);
  flex-shrink: 0;
  height: 100%;
  width: 100%;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &.rounded {
    border-radius: 50%;
    overflow: hidden;
  }
}

.failed {
  @include flex.col(center);

  img {
    height: 0 !important;
    opacity: 0 !important;
    min-height: unset;
  }

  &.withMessage {
    &::after {
      content: 'Failed to load media';
      color: colors.$grey-dark;
      margin-top: 1rem;
      font-size: 0.85em;
      font-weight: fonts.$font-weight-normal;
      letter-spacing: 2px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  svg {
    color: colors.$grey-darker;
    height: 50%;
    max-height: 5rem;
    max-width: 5rem;
    width: 50%;
  }
}

.black {
  background-color: colors.$black;

  &.failed {
    background-color: colors.$black;
  }
}

.dark {
  background-color: colors.$darker;

  &.failed {
    background-color: colors.$darker;
  }
}
