@use "../../../assets/stylesheets/flex.scss";

.wrapper {
  @include flex.col(flex-start, (full: true));
  gap: 0.5rem;
  height: 100%;
}

.scrollable_comments {
  gap: 0.25rem;
}
