@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "../../cards/Cards/styles.mod.scss";
@use "../Header/_vars.scss" as headerVars;
@use "./_vars.scss";

:global(.Tabs) {
  @include flex.row(center, (full: true, pure: true));
  @include mixins.tapered;
  padding: 0 vars.$tab-horz-spacing-mobile  0.5rem;
  position: relative;

  &:after {
    position: absolute;
    bottom: 10px;
    border-bottom: solid 1px colors.$grey-darker;
    content: '';
    left: vars.$tab-horz-spacing-mobile;
    width: calc(100% - #{vars.$tab-horz-spacing-mobile * 2});
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.wrapper {
  @include flex.row(flex-start, (full: true, pure: true));
  overflow: scroll visible;
  height: 2rem;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  .tab {
    @include mixins.titleize;
    color: colors.$grey;
    font-size: 0.8em;
    margin: 0 0.5rem 0 0;
    padding: 0.5rem 1.5rem;
    position: relative;

    .tab_hover {
      @include flex.col(center);
      bottom: 1px;
      height: 2px;
      opacity: 0;
      border-radius: 3px 3px 0 0;
      width: 0;
      position: absolute;
      background-color: colors.$grey;
    }

    &:hover:not(.active), &:focus:not(.active) {
      color: colors.$grey-light;

      .tab_hover {
        left: 0;
        opacity: 1;
        width: 100%;
        transform: translateY(-2px);
        z-index: 10;
      }
    }

    &.active {
      color: colors.$primary;
      cursor: default;
    }
  }

  .indicator {
    @include mixins.transition(150ms width, 150ms transform);
    background-color: colors.$primary;
    border-radius: 0 0 3px 3px;
    bottom: 0px;
    height: 3px;
    position: absolute;
    z-index: 1;
  }
}

.sticky {
  background-color: colors.$darker;
  position: sticky;
  top: headerVars.$header-height-mobile;
  z-index: 1;
}


@include breakpoints.desk-xxs {
  :global(.Tabs) {
    padding: 0;
    height: 2.5rem;
    // height: 3rem;
    box-shadow: 0 -0.25rem 0.25rem 0.5rem colors.$darker;
    margin-bottom: 1rem;

    &:after {
      bottom: 7px;
      left: 0;
      width: 100%;
    }
  }

  .sticky {
    top: headerVars.$header-height-desk;
  }
}


@include breakpoints.desk-xs {
  :global(.Tabs) {
    height: 3rem;

    &:after {
      bottom: 10px;
    }
  }
}
