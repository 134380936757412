@use "./breakpoints.scss";

// @font-face {
//   font-family: "Yanone Kaffeesatz";
//   src: local("Yanone Kaffeesatz"),
//     url("../fonts/Yanone_Kaffeesatz/YanoneKaffeesatz-VariableFont_wght.ttf") format("truetype");
// }

// $blinker-font-weights: "Black", "Bold", "ExtraBold", "ExtraLight", "Light", "Regular", "SemiBold", "Thin";

// @each $font-weight in $blinker-font-weights {
//   @font-face {
//     font-family: "Blinker";
//     font-weight: unquote($font-weight);
//     src: url("../fonts/Blinker/Blinker-#{$font-weight}.ttf") format("truetype");
//   }
// }

// $saira-font-weights: "Black", "Bold", "ExtraBold", "ExtraLight", "Light", "Medium", "Regular", "SemiBold", "Thin";

// @each $font-weight in $blinker-font-weights {
//   @font-face {
//     font-family: "Saira Condensed";
//     font-weight: unquote($font-weight);
//     src: url("../fonts/Saira_Condensed/SairaCondensed-#{$font-weight}.ttf") format("truetype");
//   }
// }

// $base-font-size: 1.5rem;
$base-font-size: 1rem;
// $base-font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
// $base-font: "Teko", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$base-font: "Teko", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$base-line-height: 1.5;
$menu-line-height: 1.25;
$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: 900;
