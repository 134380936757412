@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";

.base {
  @include flex.col(space-between);
}

.file {
  width: 100%;
}

.date_pickers {
  @include flex.row(space-between, (full: true));
  margin: 0.5rem 0;
}

.date_picker {
  @include flex.col(flex-start, (align: flex-start));
  margin-bottom: 1rem;
  width: 100%;

  label {
    color: #bdbdbd;
    font-size: 0.85em;
    margin-bottom: 0.5rem;
  }
}

@include breakpoints.desk-xxs {
  .date_picker {
    margin-bottom: 0;
    width: 49%;
  }
}
