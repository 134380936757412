@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";

.base {
  @include flex.col(center, (full: true));

  a {
    @include flex.col(center, (align: flex-start, full: true));
    @include mixins.titleize;
    border-radius: 0.5rem;
    color: colors.$grey;
    padding: 1rem;
    margin: 0.25rem 0;

    &:hover {
      background-color: colors.$dark;
      opacity: 0.5;
    }

    &.active {
      background-color: colors.$dark;
      color: colors.$white;
      pointer-events: none;
    }
  }
}
