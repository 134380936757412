@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../../../../assets/stylesheets/mixins.scss";

:global(.SubmissionResult) {
  @include flex.row(flex-start, (full: true, pure: true));
  gap: 1rem;

  .content {
    @include flex.col(flex-start, (full: true));
    align-items: flex-start;
    gap: 0.5rem;
  }

  .categories {
    @include flex.row(flex-start, (full: true, pure: true));
    gap: 0.25rem;
  }

  .name {
    @include mixins.ellipsis-clamp(1);
  }

  .attachment {
    border-radius: 0.25rem;
    border: 1px solid colors.$grey;
    flex-shrink: 0;
    height: 6rem;
    overflow: hidden;
    width: 6rem;

    img {
      object-fit: contain;
    }
  }

  .user_time {
    @include flex.row(space-between, (full: true, pure: true));
    color: colors.$grey;
  }
}
