@use "../../assets/stylesheets/colors.scss";
@use "../../assets/stylesheets/flex.scss";
@use "../../assets/stylesheets/fonts.scss";
@use "../../assets/stylesheets/mixins.scss";
@use "../layouts/Menu/_vars.scss";

// :global(.NoContent) {
//   @include flex.col(center, (full: true));
//   @include mixins.titleize;
//   color: colors.$grey;
//   flex-grow: 1;
//   font-size: 0.85em;
//   font-weight: fonts.$font-weight-normal;
//   height: 100%;
//   letter-spacing: 2px;
//   min-height: 30vh;
//   pointer-events: none;
//   text-align: center;
// }
.base {
  @include flex.col(center, (full: true));
  @include mixins.titleize;
  color: colors.$grey;
  flex-grow: 1;
  font-size: 0.85em;
  font-weight: fonts.$font-weight-normal;
  height: 100%;
  letter-spacing: 2px;
  min-height: 30vh;
  pointer-events: none;
  text-align: center;
}
