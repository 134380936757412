@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";

.wrapper {
  @include flex.col(center, (align: flex-start, full: true));
  width: 100%;

  .toolbar {
    background-color: transparent;
    border: 0;
    border-left: 2px solid colors.$grey-darker;

    :global(.rdw-option-wrapper) {
      background-color: colors.$grey-light;
      border: 1px solid colors.$grey-light;

      &:hover {
        border-color: colors.$white;
        background-color: colors.$white;
      }

      &:global(.rdw-option-active) {
        background-color: colors.$primary;
        border-color: colors.$primary;
      }
    }

    :global(.rdw-dropdown-optionwrapper) {
      z-index: 2;
    }

    :global(.rdw-emoji-modal), :global(.rdw-link-modal) {
      background-color: colors.$dark;
      border: 1px solid colors.$darker;
      box-shadow: 3px 3px 5px colors.$darker;
    }

    :global(.rdw-link-modal) {
      height: auto;

      :global(.rdw-link-modal-target-option) {
        @include flex.row(flex-start, (full: true, pure: true));

        input[type=checkbox] {
          width: auto;
        }
      }

      :global(.rdw-link-modal-buttonsection) {
        @include flex.row(space-between, (full: true, pure: true));
        margin-top: 1rem;
      }

      :global(.rdw-link-modal-btn) {
        @include flex.col(center);
        @include mixins.titleize;
        background-color: colors.$grey-dark;
        border: 0;
        font-size: 0.7em;
        margin: 0;
      }
    }

    :global(.rdw-dropdown-wrapper) {
      background-color: colors.$grey-light;
      color: colors.$dark;

      :global(.rdw-dropdown-selectedtext) {
        color: colors.$dark;
      }
      :global(.rdw-dropdownoption-default) {
        color: colors.$dark;
      }
    }
  }

  .editor {
    background-color: colors.$dark;
    border: 1px solid colors.$grey-darker;
    border-radius: 6px;
    padding: 0 1rem 1rem;
    width: 100%;

    :global(.rdw-link-decorator-wrapper a) {
      text-decoration: underline;
    }

    :global(.rdw-link-decorator-icon) {
      display: none;
    }
  }
}
