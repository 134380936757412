@use "../../assets/stylesheets/colors.scss";
@use "../../assets/stylesheets/flex.scss";
@use "../../assets/stylesheets/mixins.scss";

.base {
  @include flex.col(center, (full: true));
  @include mixins.titleize;
  color: colors.$grey;
  height: 75vh;
  letter-spacing: 2px;
}
