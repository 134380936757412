@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";

#base {
  @include flex.col(center);
}

.wrapper {
  @include flex.row(center);
  @include mixins.tapered;
  padding: 2rem 0;
  width: 100%;
}
