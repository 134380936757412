@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";


.base {
  @include flex.row(center, (pure: true));
  @include mixins.titleize;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 0.9em;
  padding: 1em 2em;

  &:focus {
    outline: none;
  }

  &.full {
    width: 100%;
  }

  &.primary {
    background-color: colors.$primary;
    color: colors.$black;
    font-weight: 600;

    &:hover, &:focus {
      border-color: colors.$primary-bold;
      background-color: colors.$primary-bold;
    }
  }

  &.primary-inverted {
    background: transparent;
    color: colors.$primary;
    border: 1px solid colors.$primary;

    &:hover, &:focus {
      border-color: colors.$primary-bold;
      color: colors.$primary-bold;
      background-color: colors.$primary-alpha;
    }
    // &:hover, &:focus {
    //   background: darken(colors.$primary, 45%);
    // }
  }

  &.secondary {
    background-color: colors.$secondary;
    color: colors.$white;
    border: 2px solid colors.$secondary;

    &:hover, &:focus {
      background: darken(colors.$secondary, 45%);
    }
  }

  &.secondary-inverted {
    background: transparent;
    border: 1px solid colors.$secondary;
    color: colors.$secondary;

    &:hover, &:focus {
      background: darken(colors.$secondary, 10%);
      border-color: darken(colors.$secondary, 10%);
    }
  }

  &.white-inverted {
    background: transparent;
    border: 1px solid colors.$grey-light;
    color: colors.$grey-light;

    &:hover, &:focus {
      border-color: colors.$white;
      color: colors.$white;
      background: darken(colors.$white, 85%);
    }
  }
}
