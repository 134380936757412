@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../components/layouts/Menu/_vars.scss";

.content {
  @include flex.col(center, (full: true));
  height: 100%;
  flex-grow: 1;

  &.content-col {
    justify-content: space-between;

    // .Cards {
    //   justify-content: flex-start;
    //   max-width: unset;

    //   &.LoadingContainer, &.NoContent {
    //     justify-content: center;

    //     .Pages_wrapper {
    //       opacity: 0;
    //     }
    //   }
    // }
  }
}

.FormPrimary, .AssociationForms {
  padding: 1rem;
}
