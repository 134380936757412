@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "./_vars.scss";

.base {
  @include flex.col(space-between);
  max-width: vars.$form-width-sm;
  // padding: 0 1rem;
  padding: 1rem;
  gap: 1rem;
  width: 100%;
}

.field {
  @include flex.col(center, (align: flex-start));
  // padding: 0 0 1.5rem;
  position: relative;
  width: 100%;

  label {
    color: lighten(colors.$grey, 15%);
    font-size: 0.85em;
    margin-bottom: 0.5rem;
    text-transform: capitalize;
  }

  &:focus-within {
    label, svg {
      color: colors.$white;
      font-weight: 500;
    }
  }
}

@include breakpoints.desk-xxs {
  .base {
    max-width: vars.$form-width-sm;
    padding: 0;
  }
}

@include breakpoints.desk-xs {
  .base {
    max-width: vars.$form-width-lg;
  }
}
