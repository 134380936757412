@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "./_vars.scss";

.wrapper {
  @include flex.col(center, (full: true));
  border-radius: 1rem;
  flex-shrink: 0;
  object-fit: cover;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: vars.$card-bg-height;
}


@include breakpoints.desk-xxs {
  .wrapper {
    border-radius: 0.5rem;
    height: vars.$card-expanded-bg-height-xxs;
  }
}

@include breakpoints.desk-xs {
  .wrapper {
    height: vars.$card-expanded-bg-height-xs;
  }
}
