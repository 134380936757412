@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/components.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "../../../assets/stylesheets/spacing.scss";
@use "./vars.scss";

.base {
  @include flex.row(space-between, (full: true, pure: true));
  @include components.responsive-container;
  @include components.responsive-container-pad;
  background-color: colors.$darkest;
  bottom: 0;
  flex: 1 0 auto;
  height: vars.$menu-height;
  padding: 0;
  position: fixed;
  z-index: 10;

  &.active {
    background-color: colors.$black;
  }

  .logo {
    @include flex.col(center, (align: flex-start));
    display: none;
    height: auto;
    padding: 0.25rem;

    img {
      width: calc(vars.$menu-width-desktop - 0.5rem);
      height: calc(vars.$menu-width-desktop - 0.5rem);
      // width: 100%;
    }
  }

  .items {
    @include flex.row(center, (full: true, pure: true));
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0;
  }

  .items__links {
    @include flex.row(space-between, (pure: true));
    // padding: 0 1rem;
    width: 100%;
  }

  .items__buttons {
    border-left: 1px solid colors.$grey-dark;
  }

  .items__button {
    border-color: colors.$primary;
    color:  colors.$primary;
    cursor: pointer;
    height: 1rem;
    width: 1rem;

    &:hover {
      border-color: darken(colors.$primary, 10%);
      color: darken(colors.$primary, 10%);
    }
  }

  :global(.TooltipContent) {
    visibility: hidden
  }
}

.link {
  @include flex.row(center, (align: center, pure: true));
  color: colors.$grey-light;
  // margin: 0 0.5rem;
  height: vars.$menu-height;
  outline: none;
  text-align: center;
  width: 100%;

  .link__name {
    display: none;
  }

  &:hover {
    background-color: colors.$dark-alpha-80;
  }

  &.active {
    font-weight: 600;
    background-color: colors.$darkest;
    color: colors.$primary;
  }
}

.svg {
  height: 1.5rem;
  width: 1.5rem;
}

@include breakpoints.mob-md {
  .logo img {
    object-fit: contain;
    height: 100%;
  }
}

@include breakpoints.tab-md {
  .base {
    .logo img {
      height: vars.$menu-logo-size;
    }

    .items__links {
      display: flex;
    }
  }
}

@include breakpoints.desk-xxs {
  .base {
    flex-direction: column;
    padding: vars.$menu-padding-vert-lg 0;
    width: vars.$menu-width-desktop;
    height: 100%;
    // padding: vars.$menu-padding-vert-sm 0;
    position: relative;

    .logo {
      display: flex;
      margin-bottom: 2rem;
    }

    .link {
      height: 4rem;

      &.active {
        background-color: colors.$dark;
        // border-left: 2px solid;
        box-shadow: 4px 0px 0px -2px colors.$primary inset;
      }
    }

    .items {
      flex-direction: column;
    }

    .items__links {
      display: flex;
      flex-direction: column;
    }

    :global(.TooltipContent) {
      visibility: visible;
    }
  }
}
