@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "../../../components/layouts/Menu/_vars.scss";

.categories {
  @include flex.col(flex-start, (align: flex-start, full: true));
  height: 100%;
  overflow: scroll;

  :global(.Card__container) {
    align-items: flex-end;
  }

  .category {
    @include flex.row(flex-end, (full: true));
    @include mixins.titleize;
    border-bottom: 1px solid transparent;
    color: colors.$grey;
    font-size: 0.8em;
    margin: 0.5rem 0;
    padding: 0.5rem;
    text-align: center;

    &:hover {
      border-color: colors.$grey-dark;
    }

    &.active {
      background-color: colors.$darker;
      border-color: transparent;
      border-radius: 6px;
      color: colors.$white;
    }
  }
}

@include breakpoints.desk-xxs {
  .categories {
    .category {
      text-align: right;
      padding: 0.5rem 0;

      &:hover {
        border-color: colors.$grey-dark;
      }

      &.active {
        border-color: colors.$white;
        background: initial;
        border-radius: initial;
      }
    }
  }
}
