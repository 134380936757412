@use "../../assets/stylesheets/breakpoints.scss";
@use "../../assets/stylesheets/colors.scss";
@use "../../assets/stylesheets/flex.scss";
@use "../../assets/stylesheets/mixins.scss";

.base {
  @include flex.col(flex-start, (full: true));

  .head {
    @include flex.row(flex-start, (full: true, pure: true));
    @include mixins.titleize;
    color: colors.$grey-light;
    display: none;
    font-size: 0.8em;
    margin: 1rem 0;
    padding: 1rem;

    svg {
      margin-right: 1rem;
      width: 1.25rem;
    }

    h2 {
      margin: 0;
      font-size: 1em;
    }

    :global(.Card__container) {
      @include flex.row(center, (pure: true));
    }
  }

  :global(.FilterCard) {
    border-radius: 0;
    border-width: 0 0 1px 0;
  }
}

@include breakpoints.desk-xxs {
  .base {
    // border-top: 1px solid colors.$grey-darker;

    :global(.FilterCard) {
      border-bottom: 1px solid colors.$grey-darker;
    }

    .head {
      display: flex;
      width: 35rem;
      width: 100%;
    }
  }
}
