@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../../../../assets/stylesheets/mixins.scss";
@use "../../../../assets/stylesheets/spacing.scss";

$card-content-height: 10rem;
$card-height: 10rem;
$card-image-width: 15rem;

:global(.UserCard) {
  @include flex.row(center);
  overflow: hidden;
  position: relative;
}

.header {
  @include flex.col(flex-start, (align: flex-start, full: true));
  gap: 1.5rem;
}

.title {
  @include mixins.ellipsis-clamp(1);
  font-size: 1.5rem;
}

.categories {
  composes: categories from '../../Card/styles.mod.scss';
}

.img {
  width: 100%;
  height: 100%;
}

.content {
  @include flex.col(flex-start, (full: true));
  padding: 2rem 1rem;
  height: $card-content-height;
}

@include breakpoints.tab-lg {
  :global(.UserCard) {
    height: $card-height;
  }

  .img {
    width: $card-image-width;
  }
}
