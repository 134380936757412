@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/flex.scss";

$card-height: 15rem;

:global(.CompetitionCard) {
  @include flex.row(space-between, (full: true));
  overflow: hidden;
}

.content {
  @include flex.col(space-between, (full: true));
  height: 100%;
  padding: 1rem;
}

.logo {
  @include flex.col(center);
  flex-shrink: 0;
  height: 100%;
  max-height: 20rem;
  width: 100%;

  img {
    height: 100%;
    max-height: 20rem;
    object-fit: cover;
    width: 100%;
  }
}

@include breakpoints.desk-xxs {
  .base {
    height: $card-height;
  }

  .content {
    height: $card-height;
  }

  .logo {
    max-height: unset;
    width: 15rem;
  }
}

@include breakpoints.desk-xs {
  .logo {
    width: 20rem;
  }
}
