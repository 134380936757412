@use "../../../../assets/stylesheets/breakpoints.scss";
@use "./_vars.scss";

@include breakpoints.desk-xxs {
  .name {
    width: vars.$name-col-width;
  }

  .category {
    width: vars.$category-col-width;
  }

  .challenges {
    width: vars.$challenges-col-width;
  }

  .reward {
    width: vars.$reward-col-width;
  }

  .actions {
    width: vars.$actions-col-width;
  }
}
