@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";

.base {
  @include flex.row(center, (pure: true));
  @include mixins.titleize;
  border-radius: 5px;
  border: 1px solid;
  cursor: pointer;
  letter-spacing: 2px;
  padding: 1em 2em;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.full {
    width: 100%;
  }

  &.primary {
    background-color: colors.$primary;
    border-color: colors.$primary;
    color: colors.$dark;
    font-weight: 700;

    &:hover:enabled, &:focus:enabled {
      background-color: colors.$primary-bold;
      border-color: colors.$primary-bold;
    }
  }

  &.primary-inverted {
    background: transparent;
    color: colors.$primary;
    border-color: colors.$primary;

    &:hover:enabled, &:focus:enabled {
      border-color: colors.$primary-bold;
      color: colors.$primary-bold;
      background-color: colors.$primary-alpha;
    }
  }

  &.secondary {
    background-color: colors.$secondary;
    border-color: colors.$secondary;
    color: colors.$white;

    &:hover:enabled, &:focus:enabled {
      background: darken(colors.$secondary, 10%);
      border-color: darken(colors.$secondary, 10%);
    }
  }

  &.secondary-inverted {
    background: transparent;
    border-color: colors.$secondary;
    color: colors.$secondary;

    &:hover:enabled, &:focus:enabled {
      background-color: colors.$secondary-alpha;
      border-color: lighten(colors.$secondary, 10%);
      color: lighten(colors.$secondary, 10%);
    }
  }

  &.black {
    background-color: colors.$black;
    border-color: colors.$black;
    color: colors.$white;

    &:hover:enabled, &:focus:enabled {
      background: lighten(colors.$black, 2%);
      border-color: lighten(colors.$black, 2%);
    }
  }

  &.grey {
    background: lighten(colors.$dark, 10%);
    color: colors.$grey-light;
    border-color: colors.$grey-dark;

    &:hover:enabled, &:focus:enabled {
      background: lighten(colors.$dark, 15%);
      border-color: colors.$grey;
    }
  }

  &.grey-inverted {
    background: transparent;
    color: colors.$grey-light;
    border-color: colors.$grey-light;

    &:hover:enabled, &:focus:enabled {
      background: lighten(colors.$dark, 5%);
      border-color: colors.$grey;
    }
  }

  &.white {
    background: darken(colors.$white, 10%);
    border-color: darken(colors.$white, 10%);
    color: colors.$dark;

    &:hover:enabled, &:focus:enabled {
      background-color: colors.$white;
      border-color: colors.$white;
    }
  }

  &.white-inverted {
    background: transparent;
    color: colors.$white;
    border-color: colors.$white;

    &:hover:enabled, &:focus:enabled {
      background: darken(colors.$white, 75%);
    }
  }

  &.red {
    background-color: colors.$red;
    color: colors.$white;
    border-color: colors.$red;

    &:hover:enabled, &:focus:enabled {
      background: darken(colors.$red, 5%);
    }
  }

  &.red-inverted {
    background: transparent;
    color: colors.$red;
    border-color: colors.$red;

    &:hover:enabled, &:focus:enabled {
      background: darken(colors.$red, 60%);
    }
  }
}
