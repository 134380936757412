@use "../../../assets/stylesheets/spacing.scss";
@use "../../../components/layouts/Header/vars.scss";

$messages-input-height: 6rem;
$messages-container-height: calc(
  100vh
  - (#{spacing.$padding-layout-vert-sm} * 2)
  - #{vars.$header-height-desk}
  - #{$messages-input-height}
);
