@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../_vars.scss";

.wrapper {
  @include flex.col(center, (full: true));
  height: 100%;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}

.background {
  height: vars.$card-expanded-bg-height-mob;
}

.content {
  @include flex.col(space-between, (align: space-between, full: true));
  height: 100%;
  padding: 1rem 0 0;
}

.header {
  @include flex.col(flex-start, (align: flex-start, full: true));
  gap: 1.5rem;
}

.title {
  font-size: 1.25em;
  overflow: hidden;
}

.description {
  color: colors.$grey;
  line-height: 1.25;
  overflow: hidden;
}

.rewards {
  @include flex.row(flex-end, (pure: true));
  bottom: 1rem;
  flex-wrap: wrap-reverse;
  position: absolute;
  right: 1rem;
}

.reward {
  background-color: colors.$darker;
  border-radius: 6px;
  border: 1px solid colors.$grey-dark;
  padding: 0.25rem 0.5rem;
  margin-left: 0.5rem;
  // align-self: flex-end;
}

@include breakpoints.desk-xxs {
  .CardExpanded {
    width: 100%;
    height: 100%;
  }
}

@include breakpoints.desk-xs {
  .background {
    height: vars.$card-expanded-bg-height-sm;
  }
}

@include breakpoints.desk-sm {
  .background {
    height: vars.$card-expanded-bg-height-desk;
  }
}
