@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../../../../assets/stylesheets/mixins.scss";

#foobar {
  color: red;
}

.edit_mode {
  display: none;

  &.active {
    @include flex.col();
  }
}

.AssociationForms {
  @include flex.col(center, (full: true));

  .AssociationForm__container {
    @include flex.row(center, (full: true, pure: true, wrap: true));
    background-color: transparent;
    border: 0;
  }

  h2 {
    @include mixins.title-primary;
    font-size: 0.9em;
    margin-top: 2rem;
    text-align: center;
  }

  & > .Button--secondary--inverted {
    margin-top: 2rem;
  }
}

.RuleSection__container {
  @include flex.row(center, (pure: true));
  border: 1px solid #333;
  background: #191919;
  border-radius: 6px;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin: 0.5rem;

  svg {
    color: colors.$grey-dark;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }

  &:hover {
    background: lighten(#191919, 5%);

    svg {
      color: colors.$grey;
    }
  }

  &.active {
    background: black;
    border-color: colors.$white;

    svg {
      color: colors.$white;
    }
  }
}

.PreviewMode {
  @include flex.col();

  .Badge {
    width: max-content
  }
}

.RuleSectionForm__container {
  @include flex.col();
  background: #191919;
  border-radius: 6px;
  border: 1px solid #333;
  margin-top: 2rem;
  padding: 1rem;
  position: relative;
  width: 100%;

  .SectionForm {
    min-width: unset;
  }

  .RuleSectionForm__close {
    color: colors.$grey-dark;
    height: 1.25rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 1.25rem;

    &:hover {
      color: colors.$white;
    }
  }

  h2 {
    @include mixins.title-primary;
    font-size: 0.75em;
    margin: 0 0 2rem;
    text-align: center;
  }
}
