@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";

:global(.BlankUtilMenu) {
  @include flex.col(center, (full: true));
  height: 100%;
}

.img {
  height: 6rem;
  width: 6rem;

  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: rotate;
  animation-timing-function: linear;
}

@-webkit-keyframes rotate {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(-360deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(-360deg);
  }
}
