@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";

.buttons {
  @include flex.row(center, (full: true));
  margin-top: 1rem;

  .Button {
    margin-top: 1rem;
  }
}

.content {
  @include flex.col(center, (full: true));
  @include mixins.tapered;
}

.title {
  color: colors.$white;
}

.username {
  font-weight: 600;
  color: colors.$primary;
}

@include breakpoints.desk-xxs {
  .buttons {
    gap: 0.5rem;
  }
}
