@use "../../assets/stylesheets/flex.scss";
@use "../layouts/Menu/_vars.scss";

:global(.LoadingContainer) {
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  margin: 0;

  .container {
    @include flex.col(center);
    height: 100%;
    flex-shrink: 1;
    flex-grow: 1;
  }

  .text {
    @include flex.col(center);
    margin-top: 2rem;
    color: #838383;
    text-align: center;
  }
}
