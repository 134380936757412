@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";

.PageButtons {
  @include flex.row(center);
}

.button {
  @include flex.row(center, (pure: true));
  background-color: colors.$darkest;
  border: 1px solid colors.$primary;
  color: colors.$primary;
  cursor: pointer;
  font-size: 0.55em;
  line-height: 1;
  min-height: 1.5rem;
  padding: 0.3rem 0.6rem;

  &:hover:enabled:not(.PageButton--active) {
    background: #{colors.$primary}35;
  }
}

.pageDots {
  padding: 0 0.25rem;
}

.nextButton, .prevButton {
  span {
    display: none;
  }
}

.nextButton, .prevButton {
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  svg {
    height: 1rem;
    width: 1rem;
  }
}

.nextButton {
  span {
    margin-right: 0.25rem;
  }
}

.prevButton {
  span {
    margin-left: 0.25rem;
  }
}

.active {
  background-color: colors.$primary;
  color: colors.$dark;
  cursor: default;
  font-weight: 600;
}

.pageButtons {
  @include flex.row(center, (pure: true));
}

@include breakpoints.tab-sm {
  .pageButton {
    border-radius: 0.25rem;
    margin: 0.25rem;
  }

}

@include breakpoints.desk-xxs {
  .button {
    border-radius: 0.25rem;
    font-size: 0.7em;
    padding: 0.5rem 1rem;
  }

  .nextButton, .prevButton {
    span {
      display: flex;
    }
  }

  .pageButton {
    margin: 0 0.25rem;
  }
}

@include breakpoints.desk-sm {
  .button {
    padding: 0.75rem 1.25rem;
  }

  .pageButton {
    margin: 0 0.5rem;
  }
}
