@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";

.header {
  @include flex.col(center);
  align-items: flex-start;
  flex-direction: column;
  gap: 1.5rem;
}

.name {
  font-size: 1.25em;
}

.description {
  color: colors.$grey;
}

.categories, .members {
  @include flex.row(center);
  gap: 0.25rem;
  align-self: flex-end;
  flex-direction: row;
}

.categories {
  justify-content: flex-end;
  flex-wrap: wrap;
}
