@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/spacing.scss";

.base {
  @include flex.col(flex-start);
  flex: 1;
  height: 100%;
  // padding: 0 0 spacing.$PageContent-padding-top;
  // padding: 0 1rem;
  position: relative;
  width: 100%;
}

.form {
  justify-content: center;
}

@include breakpoints.desk-xxs {
  .base {
    padding: 0;
  }
}
