@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "./_vars.scss";

:global(.DropdownCard) {
  @include flex.col(flex-start, (full: true));
  @include mixins.transition(150ms height);
  background-color: colors.$dark;
  border-radius: 0.25rem;
  border-top: 1px solid colors.$grey-darker;
  border: 1px solid colors.$grey-darker;
  color: colors.$grey-light;
  height: vars.$dropdown-card-height-short;
  max-height: unset;
  max-width: initial;

  &:last-child {
    border-bottom: 1px solid colors.$grey-darker;
  }

  &:hover, &:focus-within {
    background-color: colors.$dark;
    border-color: colors.$grey;
    cursor: pointer;
  }
}

.no_container {
  background-color: transparent;
  border-radius: 0;
  border-width: 1px 0 0 0;
  height: vars.$dropdown-card-height-tall;

  &:hover, &:focus-within {
    border-color: colors.$grey-darker;
  }
}

.active {
  height: 30rem;

  :global(.DropdownCardContent) {
    height: 100%;
    overflow: hidden;
    padding: 0 1rem 1rem;
    visibility: visible;
    width: 100%;
  }

  :global(.DropdownCardHeader) svg {
    // transform: rotateX(180deg);
    transform: rotateZ(180deg);
  }
}

.mobile_collapse {
  @include flex.row(center, (pure: true));
  background-color: colors.$dark;
  border-radius: 0 0 0 6px;
  border-width: 0 0 1px 1px;
  border: 1px solid colors.$grey-dark;
  font-size: 0.75em;
  letter-spacing: 1px;
  padding: 0.25rem 1rem;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  top: 100%;

  svg {
    height: 1rem;
    margin-left: 0.5rem;
    width: 1rem;
  }
}

@include breakpoints.desk-xxs {
  .mobile_collapse {
    display: none;
  }

  .FilterCard {
    width: 100%;
  }
}

@include breakpoints.desk-sm {
  .active {
    height: auto;
    height: 35rem;
  }
}
