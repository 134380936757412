@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/flex.scss";

.base {
  @include flex.col(center, (full: true));

  .button {
    border-radius: 0;
  }
}

@include breakpoints.desk-xxs {
  .base {
    margin-top: 2rem;
    padding: 1rem;

    .button {
      border-radius: 0.25rem;
    }
  }
}
