@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "../../../components/layouts/Menu/_vars.scss";

.toc {
  @include flex.col(flex-start, (align: flex-start, full: true));
  overflow: scroll;
}

.toc-item {
  @include flex.col(flex-start, (align: flex-start, full: true));
  margin-bottom: 0.5rem;

  a {
    width: 100%
  }
}

.rule, .rule-section {
  @include mixins.titleize;
  border-bottom: 1px solid transparent;
  font-size: 0.8em;
  padding: 0.25rem 0;
  margin: 0.5rem 0;

  &.active {
    border-bottom: 1px solid colors.$white;
    color: colors.$white;
  }

  &:hover {
    border-bottom: 1px solid colors.$grey-dark;
  }
}

.rule {
  font-size: 1.15em;
}

.rule-section {
  margin-left: 1.5rem;
}

@include breakpoints.desk-xxs {
  .toc {
    height: 100%;
  }
}
