@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/fonts.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "../Menu/_vars.scss" as menuVars;
@use "./_vars.scss";

.base {
  @include flex.row(flex-start, (full: true, pure: true));
  height: menuVars.$menu-logo-size;
  max-width: vars.$page-title-max-width-mobile;
  text-align: left;
  text-transform: uppercase;

  h1 {
    @include mixins.ellipsis-clamp(2);
    display: block;
    color: colors.$primary;
    font-size: 0.75em;
    font-weight: fonts.$font-weight-normal;
    letter-spacing: 2px;
    line-height: calc(menuVars.$menu-logo-size / 3);
    margin: 0;
    text-transform: uppercase;
  }
}

@include breakpoints.tab-sm {
  .Menu {
    .base {
      margin-left: 2rem;
      width: auto;

      h1 {
        max-width: 15rem;
        min-width: 7rem;
      }
    }
  }
}

@include breakpoints.desk-xxs {
  .base {
    max-width: vars.$page-title-max-width-desk;

    h1 {
      line-height: calc(menuVars.$menu-logo-size / 2);
      font-size: 0.85em;
    }

    &.mobile {
      display: none;
    }
  }
}
