@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../../../../assets/stylesheets/mixins.scss";

:global(.MessageCard) {
  // @include flex.col(flex-start, (align: flex-start, full: true));
  background-color: transparent;
  border: none;
  box-shadow: none;
  border-left: 4px solid colors.$dark;
  margin-bottom: 0.5rem;
  // bor

  &:hover {
    background-color: colors.$dark;
    border-color: colors.$primary-alpha-alt-alt;
    box-shadow: none;
    filter: none;
  }
}

.header {
  @include flex.row(space-between, (pure: true, full: true));
  margin-bottom: 0.5rem;
  gap: 1rem;
}

.user {
  @include flex.row(center, (pure: true));
}

.time {
  color: colors.$grey;
}

.content {
  @include mixins.ellipsis-clamp(3);

  a {
    color: colors.$primary;
    text-decoration: underline;
  }
}
