@use "../../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../../assets/stylesheets/colors.scss";
@use "../../../../../assets/stylesheets/flex.scss";
@use "./vars.scss";

.base {
  @include flex.col();
  background-color: colors.$black;
  height: vars.$media-height;
  width: 100%;
  contain: strict;

  img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}


iframe {
  width: 100%;
}

@include breakpoints.desk-xxs {
  .base {
    contain: size;
    height: 100%;
    min-height: vars.$media-height;
  }
}
