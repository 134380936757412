@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/flex.scss";

.buttons {
  @include flex.row(center, (full: true));
  margin-top: 1rem;

  .button {
    margin-top: 1rem;
  }
}

@include breakpoints.desk-xxs {
  .buttons {
    gap: 0.5rem;
  }
}
