@use "../../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../../assets/stylesheets/colors.scss";

.add {
  color: colors.$primary;
  font-weight: 600 !important;
}

.remove {
  color: colors.$primary;
}

@include breakpoints.tab-md {
  .add {
    background-color: colors.$primary !important;
    border-color: colors.$primary !important;
    color: colors.$dark;
    font-weight: 600 !important;
  }

  .remove {
    border-color: colors.$primary !important;
    // background-color: colors.$dark;
    background-color: darken(colors.$primary, 40%) !important;
    color: colors.$primary !important;
  }
}
