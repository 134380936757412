@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../_vars.scss" as sharedVars;

$card-bg-height: 12.5rem;

.background {
  background-position: center;
  background-size: cover;
  flex-shrink: 0;
  height: $card-bg-height;
  overflow: hidden;
  position: relative;
  width: 100%;

  &.isExpanded {
    height: sharedVars.$card-expanded-bg-height-mob;
  }
}

@include breakpoints.desk-xs {
  .background.isExpanded {
    height: sharedVars.$card-expanded-bg-height-sm;
  }
}

@include breakpoints.desk-sm {
  .background.isExpanded {
    height: sharedVars.$card-expanded-bg-height-desk;
  }
}
