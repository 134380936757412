@use "../Menu/_vars.scss" as menuVars;
@use "../../popovers/HeaderPopover/_vars.scss" as HeaderPopoverVars;

$header-inner-height: 2.5rem;
$header-padding-vert-mobile: 0.5rem;
$header-padding-horz-mobile: 1rem;
$header-padding-vert-desk: 1rem;

$user-buttons-padding: 1rem;
$user-buttons-gap-mobile: 1rem;
$user-buttons-gap-desk: 1.5rem;
$user-buttons-width-mobile: calc((HeaderPopoverVars.$HeaderPopoverTrigger-width * 2) + ($user-buttons-gap-mobile * 2) + ($user-buttons-padding * 2) + menuVars.$menu-logo-size);
$user-buttons-width-desk: calc((HeaderPopoverVars.$HeaderPopoverTrigger-width * 2) + ($user-buttons-gap-desk * 2) + ($user-buttons-padding * 2) + menuVars.$menu-logo-size);

$header-height-mobile: calc(#{$header-inner-height + ($header-padding-vert-mobile * 2)});
$header-logo-width-mobile: calc(#{$header-inner-height + ($header-padding-vert-mobile * 2)});
$header-height-desk: calc(#{$header-inner-height + ($header-padding-vert-desk * 2)});
