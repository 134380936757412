@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";

.item {
  @include flex.row(space-between, (pure: true, full: true));
}

.read {
  color: colors.$grey;
}

.unread {
  color: colors.$grey-light;
}
