@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";

:global(.LeagueResult) {
  gap: 0.5rem;

  .members_categories {
    @include flex.row(space-between, (full: true, pure: true));
  }

  .categories {
    @include flex.row(flex-start, (pure: true));
    gap: 0.25rem;
  }

  .avatar_name {
    @include flex.row(flex-start, (full: true, pure: true));
  }

  .avatar {
    border: 1px solid colors.$grey;
  }
}
