@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";

.base {
  @include flex.col(space-between);
}

// .file {
//   margin-bottom: 1rem;
//   width: 100%;
// }

.media_types {
  @include flex.row(center, (pure: true));
  position: absolute;
  right: 0;
  top: 0;
}

.media_type {
  @include flex.row(center, (pure: true));
  @include mixins.titleize;
  background-color: colors.$dark;
  border-radius: 0.25rem;
  border: 1px solid colors.$grey;
  color: colors.$grey-light;
  cursor: pointer;
  font-size: 0.55em;
  margin-left: 0.5rem;
  padding: 0.25rem 0.5rem;

  svg {
    color: colors.$grey-light;
    height: 0.75rem;
    margin-right: 0.5rem;
    width: 0.75rem;
  }
}

.media_type.active {
  background-color: colors.$grey-light;
  color: colors.$dark;
  font-weight: 500;
  border-color: colors.$grey-light;

  svg {
    color: colors.$dark;
    height: 0.75rem;
    margin-right: 0.5rem;
    width: 0.75rem;
  }

  &:hover {
    cursor: default;
  }
}

.media_type:not(.active) {
  &:hover, &:focus {
    border-color: colors.$white;
    color: colors.$white;

    svg {
      color: colors.$white;
    }
  }
}
