@use "../../../../../assets/stylesheets/flex.scss";

.buttons {
  @include flex.col(flex-end, (full: true));
  margin: 1rem 0;

  button {
    margin-top: 1rem;
  }
}
