@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../pages/admin/submissions/List/_vars.scss";

button.approval {
  opacity: 0.75;

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.actions {
  gap: 1rem;
}

.active, button.approval:hover {
  opacity: 1;
}

.accept {
  color: colors.$primary;
}

.reject {
  color: colors.$red;
}

@include breakpoints.desk-xxs {
  .name {
    width: vars.$name-col-width;
  }

  .challenge {
    width: vars.$challenge-col-width;
  }

  .user {
    width: vars.$user-col-width;
  }

  .rewards {
    width: vars.$reward-col-width;
  }

  .submitted_on {
    color: colors.$grey;
    width: vars.$submitted-on-col-width;
  }

  .actions {
    justify-content: flex-end;
    width: vars.$actions-col-width;
  }
}
