// $name-col-width: 12.5%;
// $challenges-col-width: 30%;
// $description-col-width: 20%;
// $category-col-width: 12.5%;
// $reward-col-width: 12.5%;
// $actions-col-width: 12.5%;
$name-col-width: 15%;
$challenges-col-width: 40%;
// $description-col-width: 20%;
$category-col-width: 15%;
$reward-col-width: 15%;
$actions-col-width: 15%;
