@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";

.base {
  @include flex.col(flex-start, (align: flex-start, full: true));
  background-color: colors.$dark;
  border-bottom: 2px solid colors.$grey-dark;
  color: colors.$white;
  position: relative;
  text-align: left;
}

.container {
  height: 100%;
  padding: 1rem;
  @include flex.col(flex-start, (align: flex-start, full: true));
}

.categories {
  @include flex.row(flex-end, (wrap: true, pure: true, full: true));
  gap: 0.25rem;
}

@include breakpoints.tab-sm {
  .base {
    border-radius: 0.25rem;
  }
}

@include breakpoints.desk-xxs {
  .base:hover {
    box-shadow: 0 0 0 1px colors.$grey-darker;
    border-bottom-color: colors.$grey-light;
  }
}
