// Naming convention should be color-modifier. Remember to alpha sort

$black: #000000;

$blue-dark-alpha: rgb(18, 24, 31, 0.5);
$blue-dark: #12181f;
$blue: lighten(#343444, 30%);

$gold: #FFBF00;

$green: #7CE7AC;

$grey-dark: #646464;
$grey-darker: #404040;
$grey-darkest: #1C1D21;
$grey-light: #CACACA;
$grey-lighter: #EAEAF1;
$grey-lightest: #F5F5FA;
$grey: #979797;

$primary: #A1D341;
$primary-bold: #A8FF00;
$primary-alpha: #A1D3411A;
$primary-alpha-alt: #a0d34102;
$primary-alpha-alt-alt: #A1D34170;

$red-light: #FF808B;
$red: #FF4D5C;
$red-dark: #A20023;

$secondary: #6F40B6;
$secondary-alpha: rgb(111, 64, 182, 0.1);
$secondary-alpha-alt: rgb(111, 64, 182, 0.05);
$white: #FFFFFF;

$dark: #222222;
$dark-alpha-80: #22222280;
$darker: #111111;
$darkest: #191919;

// $dark: darken(#12181f, 0%);
// $darker: darken(#12181f, 6%);
// $darkest: darken(#12181f, 4%);
