@use "../../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../../assets/stylesheets/colors.scss";

.league {
  color: colors.$grey;
  font-size: 0.75em;
  white-space: pre;

  &:hover {
    text-decoration: underline;
  }
}


@include breakpoints.desk-xxs {
  .league {
    align-self: flex-start;
  }
}
