@use "../../../assets/stylesheets/breakpoints.scss";

.base {
  composes: base from '../Card/styles.mod.scss';

  &:hover {
    filter: brightness(120%);
  }
}

.container {
  composes: container from '../Card/styles.mod.scss';
}
