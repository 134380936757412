@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "./_vars.scss";

.trigger {
  width: vars.$HeaderPopoverTrigger-width;

  svg, img {
    height: 1.25rem;
    width: vars.$HeaderPopoverTrigger-width;
  }
}

.trigger {
  color: colors.$grey-light;
  text-align: center;

  &:hover {
    color: colors.$white;
  }

  &.open {
    font-weight: 600;
    color: colors.$primary;
  }

  svg, img {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.content {
  @include flex.col(flex-start, (full: true));
  max-height: vars.$content-max-height-mob;
  width: calc(100dvw - 2rem);
  // min-width: 20rem;
  overflow: scroll;
  padding: 1rem;
  pointer-events: all;
}

.content_items {
  @include flex.col(flex-start, (full: true));
  max-height: vars.$items-max-height-mob;
  overflow: scroll;
  flex-grow: 1;
}

.content_header {
  @include flex.row(space-between, (align: flex-start, full: true, pure: true));
  height: vars.$header-height;
  flex-shrink: 0;

  a, h2 {
    @include mixins.titleize;
    font-size: 0.8rem;
    margin: 0;
  }

  a {
    color: colors.$grey;
  }

  h2 {
    color: colors.$primary;
  }
}

@include breakpoints.mob-xl {
  .content {
    width: 25rem;
  }
}

@include breakpoints.tab-sm {
  .content {
    max-width: 25rem;
    max-height: vars.$content-max-height-desk;
  }

  .content_items {
    max-height: vars.$items-max-height-desk;
  }
}

@include breakpoints.desk-xxs {
  .trigger {
    overflow: hidden;
  }

  .content {
    width: 25rem;
  }
}
