@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/spacing.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "../../layouts/Menu/_vars.scss";

.base {
  @include flex.row(center, (full: true));
  overflow: hidden;
}

.content {
  @include flex.col(space-between, (full: true));
  gap: 0.5rem;
  // height: 15rem;
  // padding: 1rem;
}

.categories {
  @include flex.row(flex-start, (full: true, pure: true, wrap: true));
  gap: 0.15rem;
}

.header {
  @include flex.col(flex-start, (align: flex-start, full: true));
  gap: 1rem;
}

.title {
  @include mixins.ellipsis-clamp(1);
  font-size: 1.75em;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.description {
  @include mixins.ellipsis-clamp(3);
  color: colors.$grey;
  line-height: 1.25;
  margin-top: 0.5rem;
  overflow: hidden;
}

.times {
  @include flex.row(flex-end, (pure: true, full: true, wrap: true));
  align-self: flex-end;
  gap: 0.25rem;
}

.time {
  width: auto;
}
