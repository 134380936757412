@use "../../../../assets/stylesheets/breakpoints.scss";
@use "./_vars.scss";

@include breakpoints.desk-xxs {
  .logo {
    width: vars.$logo-col-width;
  }

  .name {
    width: vars.$name-col-width;
  }

  .description {
    width: vars.$description-col-width;
  }

  .members {
    width: vars.$members-col-width;
  }

  .categories {
    width: vars.$categories-col-width;
  }
}
