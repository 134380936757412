@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "../../../assets/stylesheets/spacing.scss";
@use "../../../components/cards/Cards/styles.mod.scss";
@use "../../../components/layouts/Header/_vars.scss" as headerVars;
@use "./vars.scss";

.wrapper {
  @include flex.col(flex-start, (full: true));
  padding: 0 1rem;
  // justify-content: flex-start;
}

.page_content {
  justify-content: flex-start;
  height: calc(100vh - (#{spacing.$padding-layout-vert-sm} * 2) - #{headerVars.$header-height-desk});
  padding: 0 0.5rem;
  // height: calc(
  //     100vh
  //     - (#{spacing.$padding-layout-vert-sm} * 2)
  //     - #{headerVars.$header-height-desk}
  //   );
}

.blank {
  @include flex.col(center, (full: true));
  @include mixins.titleize;
  font-size: 1rem;
  color: colors.$grey-light;
  font-weight: 400;
  flex: 1;
}

.LoadingContainer, .NoContent {
  height: vars.$messages-container-height;
  top: unset;
  transform: none;
}

// .Messages__container, .LoadingContainer, .NoContent {
//   height: vars.$messages-container-height;
// }

// .Messages__container {
//   @extend .Cards, .Cards--tapered;
//   display: flex;
//   overflow: scroll;
//   flex-direction: column-reverse;
//   gap: 0.5rem;
//   justify-content: flex-start;
// }

@include breakpoints.desk-xxs {
  .page_content {
    padding: 0;
  }
}
