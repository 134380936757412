@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "../../../assets/stylesheets/spacing.scss";
@use "./vars.scss";

// Do NOT use :not() pseudoselector for --unstyled. It is more specific
// and therefore overrides all other specific styling of the .Cards class.
:global(.Cards) {
  @include flex.col(center, (full: true));
  flex-shrink: 1;
  flex-grow: 1;
  gap: 0.5rem;

  // justify-content: flex-start;
  padding: 0;

  &.LoadingContainer, &.NoContent {
    justify-content: center;
  }

  .Card {
    border-width: 0 0 1px 0;
  }
}

.unstyled :global(.Card) {
  align-items: unset;
  border-width: unset;
  display: initial;
  flex-direction: unset;
  width: auto;
}

.PageWrapper--col {
  :global(.Cards) {
    justify-content: flex-start;
    padding: 0;

    &.LoadingContainer, &.NoContent {
      justify-content: center;
    }
  }

  .Cards--unstyled {
    padding: initial;
  }
}

.Cards--tapered {
  @include mixins.tapered;
}

@include breakpoints.tab-sm {
  .PageWrapper--col {
    .Cards {
      gap: vars.$gap-size;
      padding: 1rem 1rem 0;
    }

    .unstyled {
      gap: initial;
      padding: initial;
    }
  }
}

@include breakpoints.desk-xxs {
  .PageWrapper--col {
    .Cards {
      padding: 0;
    }
  }
}
