@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";

:global(.ChallengeResult) {
  gap: 0.5rem;

  .name {
    font-size: 1em;
  }

  .description {
    font-size: 0.85em;
    color: colors.$grey;
  }

  .category_rewards {
    @include flex.row(space-between, (full: true, pure: true));
  }

  .rewards {
    @include flex.row(center, (pure: true));
    gap: 1rem;
  }
}
