@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../pages/admin/rituals/List/_vars.scss";

.rewards {
  gap: 0.5rem;
}

@include breakpoints.desk-xxs {
  .name {
    width: vars.$name-col-width;
  }

  .category {
    width: vars.$category-col-width;
  }

  .challenges {
    width: vars.$challenges-col-width;
  }

  .actions {
    width: vars.$actions-col-width;
  }

  .rewards {
    width: vars.$reward-col-width;
  }

  .actions {
    width: vars.$actions-col-width;
  }
}
