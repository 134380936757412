@use "../../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../../assets/stylesheets/colors.scss";
@use "../../../../../assets/stylesheets/flex.scss";
@use "../../../../../assets/stylesheets/mixins.scss";

.trigger {
  color: colors.$grey-light;
}

.trigger.mobile {
  @include flex.row(center, (pure: true));
  @include mixins.titleize;
  padding: 0.5rem 1rem;
  background-color: colors.$dark;
  border: 1px solid colors.$grey;
  border-radius: 1rem;
  color: colors.$grey-light;

  gap: 0.5rem;
  font-size: 0.75rem;
  // background-color: colors.$grey-lighter;
  // color: colors.$grey-darkest;

  svg {
    height: 1rem;
    width: 1rem;
  }
}

@include breakpoints.desk-xxs {
  .trigger {
    background-color: colors.$grey-lighter;
    border-color: colors.$grey-lighter;
    color: colors.$dark;
    font-weight: 600;
  }
}
