@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";
@use "../../../../assets/stylesheets/mixins.scss";

:global(.NotificationCard).slim {
  background-color: transparent;
  border: none;
  box-shadow: none;
  border-left: 4px solid colors.$dark;
  margin-bottom: 0.5rem;

  &:hover {
    background-color: colors.$dark;
    border-color: colors.$primary-alpha-alt-alt;
    box-shadow: none;
    filter: none;
  }
  // }
}

.slim .content {
  @include mixins.ellipsis-clamp(3);
}

.header {
  @include flex.row(space-between, (pure: true, full: true));
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  // font-size: 0.9rem;
}

.user {
  @include flex.row(center, (pure: true));
}

.time {
  color: colors.$grey;
}

.title {
  color: colors.$primary;
}
