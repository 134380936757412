@use "../../../../../assets/stylesheets/colors.scss";

.user {
  letter-spacing: 0;
  width: max-content;
  border: 1px solid colors.$grey-dark;
}

.user_link:hover {
  .user {
    border-color: colors.$grey-light;
    color: colors.$grey-lighter;
  }
}
