@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";

.form {
  @include flex.col(center, (full: true));
  max-width: 35rem;

  .error {
    font-size: 0.75em;
    margin: 0.5rem 0;
  }

  .button-container {
    @include flex.row(center, (full: true, pure: true));
    align-self: flex-end;
    padding-bottom:  1rem;
    gap: 0.5rem;

    .button {
      width: 50%;
      margin-top: 0;
    }
  }

  .wysiwyg {
    @include flex.col(center, (align: flex-start, full: true));
    min-height: 40dvh;
  }

  .button-container {
    margin-top: 1rem;
  }

  .button-submit {
    gap: 0.5rem;

    svg {
      height: 1em;
      width: 1em;
    }
  }
}

@include breakpoints.tab-sm {
  .form {
    .wysiwyg {
      min-height: 25rem;
    }
  }
}

@include breakpoints.desk-xxs {
  .form {
    max-width: unset;

    textarea {
      margin: 0 0.5rem 0.5rem;
    }

    .button-container {
      width: 30rem;
      padding: 0;
    }
  }
}
