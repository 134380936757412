@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";

.wrapper {
  // @include flex.col(center, (full: true));
}

.container {
  @include flex.col(center, (full: true));
  height: 100%;
  padding: 2rem;
  max-width: 30rem;
}

@include breakpoints.tab-lg {
  .container {
    // max-width: unset;
    // width: auto;
  }
}

@include breakpoints.desk-xxs {
  .container {
    opacity: 1;
    padding: 2rem;
    border-radius: 0 1rem 1rem 0;
    // width: 100%;
  }
}
