@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";

.wrapper {
  @include flex.row(flex-end, (full: true, pure: true));
  flex-wrap: wrap;
  padding: 0 0 0.5rem;

  .remove {
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.item {
  @include flex.row(center, (pure: true));
  @include mixins.transition(150ms background-color);
  border-radius: 2rem;
  border: 1px solid colors.$primary;
  color: colors.$primary;
  cursor: pointer;
  font-size: 0.55em;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  position: relative;
  text-transform: uppercase;

  svg {
    height: 1rem;
    margin-left: 0.25rem;
    width: 1rem;
  }

  &:hover, &:focus {
    background-color: colors.$primary;
    color: colors.$black;

    .remove {
      opacity: 1;
    }

    .name, svg {
      opacity: 0;
    }
  }
}
