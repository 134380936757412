@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "../Menu/_vars.scss" as menuVars;
@use "./vars.scss";

.base {
  @include flex.row(flex-start, (pure: true));
  @include mixins.transition(width 0.2s ease-in-out);
  align-items: flex-start;
  background-color: colors.$darkest;
  // box-shadow: 2px 0 1rem 0 colors.$darkest;
  bottom: 0;
  display: none;
  height: vars.$utility-menu-height-mobile;
  flex-shrink: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: relative;
  text-align: left;
  visibility: hidden;
  width: 100%;
  z-index: 8;

  &.active {
    border-left: 1px solid colors.$dark;
    opacity: 1;
    padding: 0.5rem 1rem;
    pointer-events: all;
    visibility: visible;
  }

  &.no-padding {
    padding: 0;

    .content {
      padding: 0;
    }
  }
}

.title {
  @include flex.row(space-between, (pure: true, full: true));
  margin-bottom: 1rem;
  padding: 0 1rem;
  position: relative;

  // .title-icon {
  //   @include flex.col();
  //   background-color: colors.$black;
  //   border-radius: 5px;
  //   color: colors.$white;
  //   font-size: 0.75em;
  //   height: 2rem;
  //   margin-right: 1.5rem;
  //   padding: 0.5rem;
  //   width: 2rem;

  //   svg {
  //     width: 1rem;
  //   }
  // }

  // .collapse {
  //   cursor: pointer;
  //   line-height: 1;

  //   &:hover {
  //     color: colors.$black;
  //   }

  //   svg {
  //     width: 1rem;
  //   }
  // }
}

.content {
  @include flex.col(flex-start, (full: true));
  height: 100%;
  padding: 0 1rem;
  position: relative;
  overflow-y: scroll;
}

.footer {
  @include flex.row(flex-start, (full: true, pure: true));
  gap: 1.5rem;
  position: absolute;
  bottom: 0.5rem;
  padding: 0 1rem;

  a {
    color: colors.$grey;
    font-size: 0.75em;
  }
}

// .logo {
//   @include flex.col(center);
// }

// .logo {
//   img {
//     height: vars.$logo-size;
//     object-fit: contain;
//     width: vars.$logo-size;
//   }
// }

// .UtilityMenu__logo--desktop {
//   display: none;
// }

@include breakpoints.desk-xxs {
  .base {
    display: flex;
    flex-direction: column;
    height: 100%;
    top: 0;
    width: 0;

    &.active {
      width: vars.$utility-menu-width-desktop-xxs;
      padding: menuVars.$menu-padding-vert-lg 0;
    }
  }

  // .UtilityMenu__logo {
  //   margin-bottom: 4rem;
  // }

  // .UtilityMenu__logo--desktop {
  //   display: flex;
  // }

  // .UtilityMenu__logo--mobile {
  //   display: none;
  // }
}

@include breakpoints.desk-xs {
  .base {
    &.active {
      width: vars.$utility-menu-width-desktop-xs;
    }
  }
}

@include breakpoints.desk-sm {
  .base {
    &.active {
      // width: vars.$utility-menu-width-desktop-sm;
    }
  }
}

@include breakpoints.desk-md {
  .base {
    &.active {
      width: vars.$utility-menu-width-desktop-lg;
    }
  }
}
