@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/fonts.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "../../../assets/stylesheets/spacing.scss";
@use "./vars";

:global(.SearchBar) {
  @include flex.col(center, (full: true));
  display: none;
  max-width: spacing.$max-content-width;
  min-width: 25rem;
  position: relative;
  z-index: 1;

  :global(.SearchBarResults) {
    display: none;
  }

  &:focus-within {
    :global(.SearchBarResults) {
      display: flex;
    }
  }
}

.form {
  width: 100%;

  input {
    background-color: colors.$dark;
    border-radius: vars.$search-bar-height;
    border: 1px solid colors.$grey-dark;
    color: colors.$white;
    font-size: 1em;
    font-weight: fonts.$font-weight-light;
    height: vars.$search-bar-height;
    outline: none;
    padding-left: 3rem;
    width: 100%;

    &:hover, &:focus {
      border-color: colors.$grey-light;
    }
  }


  svg {
    color: darken(colors.$grey, 10%);
    font-size: 1em;
    height: 1.25rem;
    left: 1rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1.25rem;
  }

  &:focus-within {
    svg {
      color: colors.$white;
      font-weight: 500;
    }
  }
}

@include breakpoints.desk-xxs {
  :global(.SearchBar) {
    @include mixins.transition(height 100ms ease-in-out);
    display: flex;
    height: 0;

    .form {
      overflow: hidden;

      svg {
        display: none;
      }
    }

    &.active {
      height: vars.$search-bar-height;

      .form svg {
        display: flex;
      }
    }
  }
}
