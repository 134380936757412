@use "../../assets/stylesheets/breakpoints.scss";
@use "../../assets/stylesheets/colors.scss";
@use "../../assets/stylesheets/flex.scss";
@use "../../assets/stylesheets/mixins.scss";

.group {
  @include flex.col(center, (full: true));
  margin-bottom: 2rem;
  gap: 1rem;

  h2 {
    @include mixins.titleize;
    font-size: 1.15em;
    color: colors.$grey-light;
    margin: 0;
    padding: 1rem;
    width: 100%;
    border-bottom: 1px solid colors.$grey-darkest;
  }
}

.Wallet {
  @include flex.row(space-between, (align: flex-start, full: true));
  padding: 1rem;

  .coin_name {
    @include flex.row(center, (pure: true));
    gap: 2rem;
  }

  .coin_img {
    background-color: colors.$darkest;
    padding: 1rem;
    border-radius: 1rem;
    height: 5rem;
    width: 5rem;

    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  .name_balance {
    @include flex.col(flex-start, (align: flex-start));
    gap: 0.25rem;

    .name {
      @include mixins.titleize;
      font-size: 0.75rem;
      color: colors.$grey;
    }

    .balance {
      font-size: 2rem;
    }
  }
}

@include breakpoints.desk-xxs {
  .Wallet {
    padding: 1rem 2rem;
  }
}

// @-webkit-keyframes rotate {
//   0% {
//     transform: rotateY(0);
//   }
//   100% {
//     transform: rotateY(-360deg);
//   }
// }

// @keyframes rotate {
//   0% {
//     transform: rotateY(0);
//   }
//   100% {
//     transform: rotateY(-360deg);
//   }
// }
