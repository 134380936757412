@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../pages/admin/challenges/List/_vars.scss";

.name a:hover {
  text-decoration: underline;
}

.rewards {
  gap: 0.5rem;
}

@include breakpoints.desk-xxs {
  .name {
    width: vars.$name-col-width;
  }

  .description {
    width: vars.$description-col-width;
  }

  .category {
    width: vars.$category-col-width;
  }

  .rewards {
    width: vars.$reward-col-width;
  }

  .actions {
    width: vars.$actions-col-width;
  }
}
