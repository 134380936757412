@use "../../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../../assets/stylesheets/flex.scss";

.categories {
  @include flex.row(flex-end, (pure: true, full: true));
  display: none;
  flex-wrap: wrap;
  gap: 0.25rem;

  &.mobile {
    display: flex;
  }
}


@include breakpoints.desk-xxs {
  .categories {
    display: flex;
    width: auto;

    &.mobile {
      display: none;
    }
  }

}
