@use "../../../../../assets/stylesheets/colors.scss";
@use "../../../../../assets/stylesheets/flex.scss";

.clear {
  @include flex.col(center);
  cursor: pointer;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.25rem;
  right: 1rem;
  top: 1rem;
  background-color: colors.$grey-light;
  border-radius: 2rem;
  color: colors.$dark;
  border: 1px solid colors.$grey-light;
  border-radius: 1rem;

  &:hover {
    background-color: colors.$white;
    color: colors.$dark;
  }
}
