@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";
@use "../../../assets/stylesheets/mixins.scss";

.link {
  background-color: colors.$darkest;
  border-bottom: 1px solid colors.$grey-dark;
  border-radius: 0;
  font-size: 0.8em;
  padding: 1rem;
  @include flex.row(space-between, (full: true, pure: true));
  @include mixins.titleize;
  font-size: 1em;

  svg {
    width: 1rem;
    height: 1rem;
  }

  &:hover {
    background-color: lighten(colors.$dark, 5%);
  }

  &:global(.active), &:global(.active):hover {
    background-color: colors.$grey-lighter;
    color: colors.$grey-darkest;
    border-color: colors.$grey-lighter;
    font-weight: 600;
  }
}

.links {
  @include flex.col(center, (full: true));
  overflow: hidden;
}
