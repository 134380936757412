@use "../../../assets/stylesheets/flex.scss";

@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/mixins.scss";
@use "./vars.scss";

.base {
  // @include flex.flex(row, center, (pure: true));
  @include mixins.ellipsis-clamp(1);
  border-radius: 3px;
  border: 1px solid;
  font-size: 0.6rem;
  font-weight: 600;
  height: fit-content;
  letter-spacing: 1px;
  line-height: 1.75;
  padding: 0.25rem 0.75rem;
  text-transform: uppercase;
  // white-space: nowrap;
  white-space: pre-line;
  word-break: break-all;
  width: fit-content;

  svg {
    font-size: 1.5em;
    height: 0.5rem;
    margin-right: 0.25rem;
    width: 0.5rem;
  }

  &.primary {
    // background-color: colors.$darkest;
    border-color: colors.$primary;
    color: colors.$primary;
  }

  &.grey {
    background: lighten(colors.$dark, 15%);
    border-color: lighten(colors.$dark, 15%);
    color: colors.$grey-light;
    font-weight: 700;
  }


  &.white {
    background-color: colors.$white;
    // color: colors.$grey-dark;
    color: colors.$black;
    font-weight: 700;
  }
}

a > .Badge {
  &.grey {
    &:hover {
      border-color: colors.$grey-light;
    }
  }
}

@include breakpoints.desk-xxs {
  .base {
    font-size: 0.5rem;
  }
}

@include breakpoints.desk-xs {
  .base {
    font-size: 0.55rem;
  }
}
