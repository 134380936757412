@use "../../../../assets/stylesheets/breakpoints.scss";
@use "../../../../assets/stylesheets/colors.scss";
@use "../../../../assets/stylesheets/flex.scss";

.base {
  @include flex.col(center);
  border-radius: 0.5rem;
  border: 2px solid colors.$primary;
  color: colors.$primary;
  padding: 0.75rem;

  &:hover {
    background-color: colors.$primary;
    color: colors.$black;
  }
}

.svg {
  height: 1.5rem;
  width: 1.5rem;
}

.wrapper {
  @include flex.col(center);
  transform: translateY(-0.5rem);
  background-color: colors.$darkest;
  // height: 3.75rem;
  // width: 3.75rem;
  border-radius: 3.75rem;
}

.mobile .base {
  background-color: colors.$primary;
  color: colors.$dark;
  height: 3rem;
  width: 3rem;
  border-radius: 3rem;

  svg {
    width: 2rem;
    height: 2rem;
  }
}

.wrapper {
  display: none;

  &.mobile {
    display: inline-flex;
  }
}

@include breakpoints.desk-xxs {
  .wrapper {
    display: flex;
    margin-top: 5rem;
  }

  .wrapper.mobile {
    display: none;
  }
}
