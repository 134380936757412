@use "../../../assets/stylesheets/breakpoints.scss";
@use "../../../assets/stylesheets/colors.scss";
@use "../../../assets/stylesheets/flex.scss";

:global(.SearchBarResults) {
  @include flex.col(flex-start, (full: true));
  height: 25rem;
  overflow: scroll;
  padding: 0 1rem 1rem;
  position: absolute;
  top: calc(100% + 0.5rem);

  &:hover {
    border-color: colors.$grey-light;
  }

  .result_types {
    @include flex.row(space-between, (pure: true, full: true));
    background-color: colors.$dark;
    gap: 0.75rem;
    padding: 1rem 0.75rem;
    position: sticky;
    top: 0;
  }
}

.result_type {
  border-radius: 2rem;
  padding: 1em 1.5em;

  &.selected {
    pointer-events: none;
  }

  &.selected, &.selected:hover {
    background-color: colors.$grey-lighter !important;
    color: colors.$dark;
    font-weight: 700;
  }

  .LoadingContainer {
    height: fill-available;
  }
}

/////////////////////////////////////////////
/// RESULTS
/////////////////////////////////////////////


@include breakpoints.desk-sm {
  // .result_types {
  //   padding: 1rem;
  // }

  .result_type {
    border-radius: 2rem;
    padding: 1em 2em;
  }
}
