@use "../../assets/stylesheets/breakpoints.scss";
@use "../../assets/stylesheets/colors.scss";
@use "../../assets/stylesheets/flex.scss";
@use "../../assets/stylesheets/mixins.scss";

.wrapper {
  @include flex.row(space-between, (full: true, pure: true));
  padding: 1rem 0.5rem;
}

.tapered {
  @include mixins.tapered;
}

.container {
  @include flex.row(space-between, (full: true, pure: true));
}

@include breakpoints.tab-sm {
  .wrapper {
    padding: 1rem 0;
  }
}
