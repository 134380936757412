@use "../../../../../assets/stylesheets/colors.scss";

.base {
  color: colors.$red;
  margin-top: 0.25rem;

  &:first-letter {
    text-transform: uppercase;
  }
}
